import i18n from "i18next";
import HttpApi from "i18next-http-backend";

import { initReactI18next } from "react-i18next";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: "de",
    fallbackLng: "de",
    debug: true,
    resources: {
      en: {
        translation: require("./locales/en/translation.json"),
      },
      de: {
        translation: require("./locales/de/translation.json"),
      },
    },
  });

export default i18n;
