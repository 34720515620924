import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { RoutesEnum } from "src/enums/Routes";
import { withTranslation } from "react-i18next";
import { Image } from "src/shared-components/image";
import { WithTFunction } from "src/types/WithTFunction";
import { Skeleton } from "src/shared-components/skeleton";
import { UserProvider } from "src/providers/UserProvider";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";

import {
  ProfileScreenContentProps,
  ProfileScreenContentState,
} from "./index.interfaces";

class ProfileScreenContent extends Component<
  WithTFunction<ProfileScreenContentProps>,
  ProfileScreenContentState
> {
  onSignOutClicked = async (): Promise<void> => {
    await UserProvider.signOut();
  };

  onRedeemCodeClicked = async () => {
    window.location.replace(RoutesEnum.redeemCode);
  };

  render(): ReactNode {
    const { t } = this.props;

    const { user } = this.props;

    return (
      <section className="w-full h-full max-w-[380px] flex flex-col desktop:justify-center items-center overflow-clip px-6 pt-40 tablet:pt-[280px] desktop:pt-0">
        <div className="flex w-full flex-col gap-6">
          {/* user info box */}
          <div className="flex bg-white gap-4 justify-center items-center w-full pl-4 pr-5 py-3 rounded-xl">
            {/* user photo */}
            {user?.photoURL ? (
              <Image
                src={user?.photoURL ?? ""}
                className="max-w-[70px] w-full h-[70px] rounded-full"
                alt={user?.displayName!}
              />
            ) : (
              <Skeleton
                numberOfSkeletons={1}
                className="max-w-[70px] h-[70px] rounded-full"
              />
            )}
            <div className="flex flex-col w-full gap-[2px] justify-center">
              {/* user display name */}
              {user?.displayName ? (
                <p className="text-lg">{user.displayName.toUpperCase()}</p>
              ) : (
                <Skeleton numberOfSkeletons={1} className="max-w-40 h-8" />
              )}
              {/* user email */}
              {user?.email ? (
                <p className="opacity-50">{user.email}</p>
              ) : (
                <Skeleton numberOfSkeletons={1} className="max-w-40 h-4" />
              )}
            </div>
          </div>
          {/* buttons */}
          <div className="flex flex-col gap-3">
            {/* redeem code button */}
            <SimpleElevatedButton
              id="profile-redeem-code"
              text={t(Translation.screens.profile.redeemCodeButtonText)}
              onClick={this.onRedeemCodeClicked}
              disabled={user === null}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ProfileScreenContent);
