import Translation from "src/translations/locales/translation";

import { auth } from "src/firebase";
import { RoutesEnum } from "src/enums/Routes";
import { useTranslation } from "react-i18next";
import { signInWithCustomToken } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";

export function AuthResponse() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Memoize the handleRedirect function to prevent ESLint warning
  const handleRedirect = useCallback(async () => {
    setIsLoading(true);

    const callbackResponse: any = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    const { error, token, redirectURI } = callbackResponse;

    if (token) {
      try {
        await signInWithCustomToken(auth, token);
        window.location.href = redirectURI
          ? RoutesEnum.shop
          : RoutesEnum.profile;
      } catch (e) {
        const error = FirebaseAuthErrorHandler.getMessage(e, t);
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    } else if (error) {
      setErrorMessage(error ?? "Unknown error");
      setIsLoading(false);
    }
  }, [t]); // Include 't' as a dependency to prevenet ESLint warning

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return (
    <div className="w-full h-[100svh] flex justify-center items-center px-6">
      {/* loader */}
      {isLoading && <span className="loading loading-spinner loading-md" />}

      {/* show error message and redirect button */}
      {errorMessage && (
        <div className="flex flex-col gap-6 justify-center items-center w-full max-w-[500px]">
          <p>{errorMessage}</p>
          <SimpleElevatedButton
            id="authResponse-redirect"
            text={t(Translation.general.backToSignIn)}
            onClick={() => window.location.replace(RoutesEnum.signin)}
          />
        </div>
      )}
    </div>
  );
}
