import ExercisesOverviewScreen from "./overview";

import { Component, ReactNode } from "react";
import { Route, Routes } from "react-router-dom";

export default class ExercisesScreen extends Component {
  render(): ReactNode {
    return (
      <Routes>
        <Route path="/:id/" element={<ExercisesOverviewScreen />} />
      </Routes>
    );
  }
}
