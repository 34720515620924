import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class ArrowUpIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="vuesax/outline/arrow-up">
          <g id="arrow-up">
            <path
              id="Vector"
              d="M20.42 15.8C20.23 15.8 20.04 15.73 19.89 15.58L13.37 9.06002C12.89 8.58002 12.11 8.58002 11.63 9.06002L5.11002 15.58C4.82002 15.87 4.34002 15.87 4.05002 15.58C3.76002 15.29 3.76002 14.81 4.05002 14.52L10.57 8.00002C11.63 6.94002 13.36 6.94002 14.43 8.00002L20.95 14.52C21.24 14.81 21.24 15.29 20.95 15.58C20.8 15.72 20.61 15.8 20.42 15.8Z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
