import { useParams } from "react-router-dom";

/**
 * @description Get Url Params
 * @requires Functional-Component
 */
export function GetUrlParams(): string | undefined {
  const { id } = useParams();
  return id;
}
