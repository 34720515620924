import Translation from "src/translations/locales/translation";
import stepOneImage from "src/assets/images/htdma-step-one.jpg";
import stepTwoImage from "src/assets/images/htdma-step-two.jpg";
import stepFourImage from "src/assets/images/htdma-step-four.jpg";
import stepThreeImage from "src/assets/images/htdma-step-three.jpg";
import StepListItemForMobile from "./components/step-list-item/mobile";
import StepListItemForDesktop from "./components/step-list-item/desktop";

import { TFunction } from "i18next";
import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { HeaderComponent } from "src/shared-components/header";
import { FooterComponent } from "src/shared-components/footer";

export interface StepListItem {
  description: string;
  image: {
    src: string;
    alt: string;
  };
}

class HowToDeleteMyAccountScreen extends Component<WithTFunction<{}>> {
  private deleteSteps = (t: TFunction): StepListItem[] => {
    return [
      {
        image: { src: stepOneImage, alt: "how-to-delete-my-account-step-one" },
        description: t(Translation.screens.howToDeleteMyAccount.stepOneDes),
      },
      {
        image: { src: stepTwoImage, alt: "how-to-delete-my-account-step-two" },
        description: t(Translation.screens.howToDeleteMyAccount.stepTwoDes),
      },
      {
        image: {
          src: stepThreeImage,
          alt: "how-to-delete-my-account-step-three",
        },
        description: t(Translation.screens.howToDeleteMyAccount.stepThreeDes),
      },
      {
        image: {
          src: stepFourImage,
          alt: "how-to-delete-my-account-step-four",
        },
        description: t(Translation.screens.howToDeleteMyAccount.stepFourDes),
      },
    ];
  };

  render(): ReactNode {
    const { t } = this.props;

    return (
      <main className="w-full min-h-[100svh] bg-white items-center flex flex-col justify-between">
        {/* header */}
        <HeaderComponent showLogoutButton={false} />

        {/* content */}
        <div className="w-full flex flex-col gap-16 py-10 !pb-36 px-7 tablet:p-16 desktop:px-20 text-center">
          <div className="flex flex-col gap-10 tablet:gap-10 desktop:gap-12">
            {/* title */}
            <h1 className="text-center font-new-atten-bold text-lg">
              {t(Translation.screens.howToDeleteMyAccount.title)}
            </h1>
            {/* subtitle */}
            <p className="text-lg">
              {t(Translation.screens.howToDeleteMyAccount.subtitle)}
            </p>
          </div>
          {/* step list for mobile and tablet */}
          <StepListItemForMobile steps={this.deleteSteps(t)} />
          {/* step list for desktop */}
          <StepListItemForDesktop steps={this.deleteSteps(t)} />
        </div>

        {/* footer */}
        <FooterComponent />
      </main>
    );
  }
}

export default withTranslation()(HowToDeleteMyAccountScreen);
