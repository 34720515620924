import { ModalDialogProps } from "./index.interfaces";

export class ModalService {
  private static instance: ModalService | null = null;
  private showModalCallback: ((modal: ModalDialogProps) => void) | null = null;

  private constructor() {}

  public static getInstance(): ModalService {
    if (!ModalService.instance) {
      ModalService.instance = new ModalService();
    }
    return ModalService.instance;
  }

  public setShowModalCallback(
    callback: (modal: ModalDialogProps) => void
  ): void {
    this.showModalCallback = callback;
  }

  private setModal({
    title,
    dialogText,
    acceptButton,
    cancelButton,
  }: ModalDialogProps): void {
    if (this.showModalCallback) {
      this.showModalCallback({
        title,
        dialogText,
        acceptButton,
        cancelButton,
      });
    }
  }

  public static showModal({
    title,
    dialogText,
    acceptButton,
    cancelButton,
  }: ModalDialogProps): void {
    ModalService.getInstance().setModal({
      title,
      dialogText,
      acceptButton,
      cancelButton,
    });
  }

  public clear(): void {
    ModalService.showModal({
      title: "",
      dialogText: "",
    });
    ModalService.instance = null;
    this.showModalCallback = null;
  }
}
