import { TextWithLineBreaksProps } from "./index.interfaces";

export default function TextWithLineBreaks({
  text,
  className,
}: TextWithLineBreaksProps) {
  return (
    <div>
      {text.split("\n").map((line, index) => (
        <p key={index} className={`${className}`}>
          {line}
          {index < text.split("\n").length - 1 && <br />}
        </p>
      ))}
    </div>
  );
}
