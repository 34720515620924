import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class CheckIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="Interface / Check">
          <path
            id="Vector"
            d="M4 8.0003L6.82843 10.8287L12.4853 5.17188"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
}
