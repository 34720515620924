import Translation from "src/translations/locales/translation";

import { DynamicForm } from "src/composable/base-dynamic-form";
import { ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { SimpleTextInput } from "src/shared-components/form/fields/text-fields/simple-text-input";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { ArrowSquareLeftIcon } from "src/assets/icons/components/ArrowSquareLeftIcon";
import { RoutesEnum } from "src/enums/Routes";
import { EmailValidator } from "src/components/email";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { Link } from "react-router-dom";
import { Form } from "src/shared-components/form";
import {
  ForgotPasswordScreenFormProps,
  ForgotPasswordScreenFormState,
} from "./index.interfaces";

class ForgotPasswordScreenForm extends DynamicForm<
  WithTFunction<ForgotPasswordScreenFormProps>,
  ForgotPasswordScreenFormState
> {
  _emailValidator: EmailValidator;

  state: Readonly<ForgotPasswordScreenFormState> = {
    isLoading: false,
  };

  constructor(props: WithTFunction<ForgotPasswordScreenFormProps>) {
    super(props);
    this._emailValidator = new EmailValidator(props.t);
  }

  onDisableSubmitButton = (): boolean => {
    return false;
  };

  onBackToSignInScreenButtonClicked = () => {
    window.location.href = RoutesEnum.signin;
  };

  onSubmit = async () => {
    const { email } = this.props;

    // validate the email
    if (!email.trim()) return;
    if (this._emailValidator.validateEmail(email)) return;

    try {
      await this.props.onResetPasswordButtonClicked();
    } catch (e) {
      FirebaseAuthErrorHandler.handle(e, this.props.t);
    }
  };

  render(): ReactNode {
    const { t, email, onEmailChanged } = this.props;

    return (
      <section className="flex flex-col gap-8 desktop:gap-20 w-full max-w-[300px] py-16 tablet:py-28 desktop:py-0 desktop:min-w-[50vw] desktop:px-[12.5%]">
        {/* back to sign-in screen button */}
        <Link
          to={RoutesEnum.signin}
          className="cursor-pointer w-fit h-fit flex items-center gap-3"
        >
          <ArrowSquareLeftIcon className="stroke-on-background" />
          <h1 className="hidden tablet:block">
            {t(Translation.screens.forgotPassword.backToSigninScreenButton)}
          </h1>
        </Link>
        <Form
          className="flex flex-col gap-6 w-full py-16 tablet:py-28 desktop:py-0"
          onSubmit={this.onSubmit}
        >
          {/* title and description */}
          <div className="flex flex-col gap-3">
            {/* title */}
            <h1 className="text-lg font-new-atten-bold">
              {t(Translation.screens.forgotPassword.form.title).toUpperCase()}
            </h1>
            {/* description */}
            <p className="opacity-50 text-normal">
              {t(Translation.screens.forgotPassword.form.subTitle)}
            </p>
          </div>
          {/* email input */}
          <SimpleTextInput
            id="forgotPassword-email-input"
            name="forgotPassword-email-input"
            placeholder={t(
              Translation.screens.forgotPassword.form.emailPlaceholder
            )}
            value={email}
            onChange={onEmailChanged}
            maxLength={100}
            validator={this._emailValidator.validateEmail}
          />
          {/* submit button */}
          <SimpleElevatedButton
            id="forgotPassword-submit-button"
            text={t(Translation.screens.forgotPassword.form.submitButton)}
            onClick={this.onSubmit}
            disabled={false}
          />
        </Form>
      </section>
    );
  }
}

export default withTranslation()(ForgotPasswordScreenForm);
