import SimpleInput from "../index.component";

import { SimpleTextInputProps, SimpleTextInputState } from "./index.interfaces";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { FormFieldErrorMessage } from "src/utils/FormFieldErrorMessage";

class SimpleTextInput extends SimpleInput<
  string,
  WithTFunction<SimpleTextInputProps<string>>,
  SimpleTextInputState
> {
  state: Readonly<SimpleTextInputState> = {
    errorMessage: null,
    disabled: true,
  };

  componentDidMount(): void {
    // due to auto focus in form we have to enable the input after 500ms
    setTimeout(() => this.setState({ disabled: false }), 300);
  }

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  render() {
    const { id, name, placeholder, value, icon, maxLength } = this.props;
    const { errorMessage, disabled } = this.state;

    return icon ? (
      <div className="relative">
        <span className="absolute left-4 top-[50%] -translate-y-[50%]">
          {icon}
        </span>
        <input
          id={id}
          className="w-full h-10 flex justify-center items-center px-3 pl-12 text-sm rounded-lg bg-white selection:bg-on-background selection:text-background"
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.onInputChange(e)}
          maxLength={maxLength}
          disabled={disabled}
        />
      </div>
    ) : (
      <div className="flex flex-col gap-1">
        <input
          id={id}
          className="w-full h-10 flex justify-center items-center px-3 text-sm rounded-lg bg-white selection:bg-on-background selection:text-background"
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.onInputChange(e)}
          maxLength={maxLength}
          disabled={disabled}
        />
        <FormFieldErrorMessage errorMessage={errorMessage} />
      </div>
    );
  }
}

export default withTranslation()(SimpleTextInput);
