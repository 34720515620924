export class FormatPriceAmount {
  public static withoutCurrency(amount: number): string {
    return (amount / 100).toFixed(2).replace(".", ",");
  }

  public static withCurrency(amount: number): string {
    return `${FormatPriceAmount.withoutCurrency(amount)}€`;
  }

  public static withStringCurrency(amount: number): string {
    return `${FormatPriceAmount.withoutCurrency(amount)} Euro`;
  }
}
