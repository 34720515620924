import { Component, ReactNode } from "react";
import { RoutesEnum } from "src/enums/Routes";
import { ArrowUpIcon } from "src/assets/icons";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";

import {
  ScrollToTapButtonProps,
  ScrollToTapButtonState,
} from "./index.interfaces";

class ScrollToTapButton extends Component<
  WithTFunction<ScrollToTapButtonProps>,
  ScrollToTapButtonState
> {
  state: Readonly<ScrollToTapButtonState> = {
    isVisible: false,
  };

  componentDidMount() {
    // get the initial scroll position
    const scrollTop = window.scrollY;

    // set isVisible based on the initial scroll position
    this.setState({ isVisible: scrollTop > 300 });

    // add the scroll event listener
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // show the button when the user scrolls down
  handleScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > 300) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  // scroll to the top when the button is clicked
  scrollToTop = () => {
    this.setState({ isVisible: false });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render(): ReactNode {
    const { isVisible } = this.state;

    return (
      <button
        className={`${isVisible ? "fixed" : "hidden"} ${
          // for the shop screen we need to change the button's position to avoid UI issues
          RoutesEnum.shop.includes(window.location.pathname) && "!hidden"
        } right-10 tablet:right-20 bg-primary p-2 rounded-full cursor-pointer transition-opacity duration-300 z-[100]`}
        onClick={this.scrollToTop}
      >
        <ArrowUpIcon className="w-10 h-10 fill-background z-[100]" />
      </button>
    );
  }
}

export default withTranslation()(ScrollToTapButton);
