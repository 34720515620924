import BaseRepository from "src/composable/base-repository/index.repository";
import ExercisesController from "./index.controller";

import { IExercisesQuery, IExercisesSort } from "./index.interfaces";
import { Exercise } from "./index.models";

class ExercisesRepository extends BaseRepository<
  IExercisesQuery,
  IExercisesSort,
  Exercise
> {
  private static controller = new ExercisesController();

  constructor() {
    super({
      controller: ExercisesRepository.controller,
    });
  }
}

export default ExercisesRepository;
