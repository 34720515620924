import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { ArrowCircleUpIcon } from "src/assets/icons";

export default function ShopHomeScreenScrollToTopButton() {
  const { t } = useTranslation();

  // scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="w-full pb-8 flex justify-center items-center">
      <button
        id="shop-scroll-to-top-button"
        onClick={() => scrollToTop()}
        disabled={false}
        className="flex items-center gap-2 bg-white text-primary px-6 py-3 border-0 rounded-full"
      >
        <ArrowCircleUpIcon className="fill-primary" />
        <p className="translate-y-[1px] font-new-atten-bold">
          {t(Translation.general.backToTop).toUpperCase()}
        </p>
      </button>
    </div>
  );
}
