import { auth } from "../firebase";
import { User } from "firebase/auth";

export class UserProvider {
  public static getCurrentUser(): User | null {
    return auth.currentUser;
  }

  public static async signOut(): Promise<void> {
    localStorage.removeItem("cart-store");
    await auth.signOut();
  }
}
