import { CloseCircleIcon, TickCircleIcon } from "src/assets/icons";
import { useLocation } from "react-router-dom";
import { HeaderComponent } from "src/shared-components/header";

export default function EmailVerificationResponseScreen() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // access query parameters
  const success: boolean = queryParams.get("success") === "true" ? true : false;
  const verificationResponse = queryParams.get("verificationResponse");

  return (
    <section className="w-full h-[100svh] relative">
      <HeaderComponent />
      {/* content */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center px-6">
        <div className="flex flex-col items-center justify-center gap-4">
          {success ? (
            <TickCircleIcon className="stroke-on-background-2 opacity-70 w-20 h-20" />
          ) : (
            <CloseCircleIcon className="stroke-on-background-2 opacity-70 w-20 h-20" />
          )}
          <p className="text-normal text-center">{verificationResponse}</p>
        </div>
      </div>
    </section>
  );
}
