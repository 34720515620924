import Endpoints from "src/constants/endpoints";
import AxiosClient from "src/networking/axios-client/index.client";

import { TermsOfService } from "./index.models";
import { AxiosResponse } from "axios";

class TermsOfServicesController {
  async getLatest(): Promise<TermsOfService> {
    const res = await AxiosClient.get({
      url: Endpoints.termsOfServices,
      parser: (res) =>
        BaseControllerOverviewResposne.fromAxiosResponse<TermsOfService>(res),
    });

    return res.data;
  }

  async getByVersion(version: string): Promise<TermsOfService> {
    const res = await AxiosClient.get({
      url: Endpoints.termsOfServices,
      queryParams: { version: version },
      parser: (res) =>
        BaseControllerOverviewResposne.fromAxiosResponse<TermsOfService>(res),
    });

    return res.data;
  }
}

class BaseControllerOverviewResposne<T> {
  constructor(public readonly data: T) {}

  static fromAxiosResponse<T>(
    res: AxiosResponse
  ): BaseControllerOverviewResposne<T> {
    return new BaseControllerOverviewResposne(res.data);
  }
}

export default TermsOfServicesController;
