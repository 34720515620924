import { AxiosResponse } from "axios";
import { CouponCollectionResponse } from "../../collection";
import { CouponEntitlementResponse } from "../../entitlement";

export class CouponResponse {
  public readonly grantedCollections: CouponCollectionResponse[];
  public readonly grantedEntitlement: CouponEntitlementResponse | null;

  constructor({
    grantedCollections,
    grantedEntitlement,
  }: {
    grantedCollections: CouponCollectionResponse[];
    grantedEntitlement: CouponEntitlementResponse | null;
  }) {
    this.grantedCollections = grantedCollections;
    this.grantedEntitlement = grantedEntitlement;
  }

  public static fromJson(data: any): CouponResponse {
    if (
      !Array.isArray(data.grantedCollections) ||
      (data.grantedEntitlement !== null &&
        typeof data.grantedEntitlement !== "object")
    )
      throw new Error("Invalid response data for CouponResponse");

    return new CouponResponse(data);
  }

  public static fromAxiosResponse(response: AxiosResponse): CouponResponse {
    return CouponResponse.fromJson(response.data);
  }
}
