import { Coupon } from "./index.models";

class CouponRespository {
  public static redeemCode = async ({ code }: { code: string }) => {
    const res = await Coupon.getInstance().redeemCode({ code });
    return res;
  };
}

export default CouponRespository;
