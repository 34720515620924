import { auth } from "src/firebase";
import { User } from "firebase/auth";
import { Component, ReactNode } from "react";
import { RoutesEnum } from "src/enums/Routes";
import { ProfileScreenContent } from "./sections/content";
import { HeaderComponent } from "src/shared-components/header";

interface ProfileScreenState {
  user: User | null;
}

export default class ProfileScreen extends Component<{}, ProfileScreenState> {
  state: Readonly<ProfileScreenState> = {
    user: null,
  };

  componentDidMount(): void {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        window.location.replace(RoutesEnum.signin);
      } else {
        this.setState({ user });
      }
    });
  }

  render(): ReactNode {
    const { user } = this.state;

    return (
      <main className="w-full h-[100svh] bg-background flex flex-col items-center">
        <HeaderComponent />
        <ProfileScreenContent user={user} />
      </main>
    );
  }
}
