import Endpoints from "src/constants/endpoints";
import AxiosClient from "src/networking/axios-client/index.client";

import { FAQ } from "./index.models";
import { AxiosResponse } from "axios";

class FAQsController {
  async getAll(): Promise<FAQ[]> {
    const res = await AxiosClient.get({
      url: Endpoints.faqs,
      parser: (res) =>
        BaseControllerOverviewResposne.fromAxiosResponse<FAQ>(res),
    });

    return res.data;
  }
}

class BaseControllerOverviewResposne<T> {
  constructor(public readonly data: T[]) {}

  static fromAxiosResponse<T>(
    res: AxiosResponse
  ): BaseControllerOverviewResposne<T> {
    return new BaseControllerOverviewResposne(res.data.data);
  }
}

export default FAQsController;
