import FAQsController from "./index.controller";

import { FAQ } from "./index.models";

class FAQsRepository {
  private controller = new FAQsController();

  async getAll(): Promise<FAQ[]> {
    const response = await this.controller.getAll();
    return response;
  }
}

export default FAQsRepository;
