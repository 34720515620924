import PrivacyPoliciesController from "./index.controller";

import { PrivacyPolicy } from "./index.models";

class PrivacyPoliciesRepository {
  private controller = new PrivacyPoliciesController();

  async getLatest(): Promise<PrivacyPolicy> {
    const response = await this.controller.getLatest();
    return response;
  }

  async getByVersion(version: string): Promise<PrivacyPolicy> {
    const response = await this.controller.getByVersion(version);
    return response;
  }
}

export default PrivacyPoliciesRepository;
