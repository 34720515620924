import Translation from "src/translations/locales/translation";

import { useCartStore } from "src/store";
import { RoutesEnum } from "src/enums/Routes";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BagIcon, LogoutIcon } from "src/assets/icons";
import { UserProvider } from "src/providers/UserProvider";

export default function HeaderCTAs({
  showLogoutButton,
}: {
  showLogoutButton?: boolean;
}) {
  const { t } = useTranslation();
  const { cart } = useCartStore();
  const location = useLocation();
  const showCartIcon: boolean = location.pathname === RoutesEnum.shop;

  const onSignOutClicked = async (): Promise<void> => {
    await UserProvider.signOut();
    // redirect to signin page
    window.location.href = RoutesEnum.signin;
  };

  // scroll to the bottom when the button is clicked
  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  return (
    <div className="flex items-center gap-4">
      {/* <UserIcon className="fill-on-background cursor-pointer" />
        <SearchIcon className="fill-on-background cursor-pointer" /> */}
      {showCartIcon && cart.length !== 0 && (
        <button
          onClick={scrollToBottom}
          className="relative hidden desktop:flex"
        >
          <BagIcon className="fill-on-background w-6 h-6" />
          {/* items in cart */}
          <p className="text-xsm bg-primary w-4 h-4 rounded-md absolute -left-1 -bottom-1 flex justify-center items-center text-background">
            {cart.length}
          </p>
        </button>
      )}
      {showLogoutButton !== false && (
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={async () => await onSignOutClicked()}
        >
          <p className="font-new-atten-medium small-mobile:hidden">
            {t(Translation.common.logout).toUpperCase()}
          </p>
          <LogoutIcon className="stroke-on-background rotate-180 w-[18px] h-[18px]" />
        </div>
      )}
    </div>
  );
}
