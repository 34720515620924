import minddropsLogo from "src/assets/images/minddrops-logo.png";
import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { AppLogoProps, AppLogoState } from "./index.interfaces";
import { WithTFunction } from "src/types/WithTFunction";
import { SafeLink } from "src/shared-components/safe-link";
import { Image } from "src/shared-components/image";

class AppLogo extends Component<WithTFunction<AppLogoProps>, AppLogoState> {
  render(): ReactNode {
    const { t, iconOnClickUrl: iconUrl } = this.props;

    return (
      <SafeLink to={iconUrl}>
        {/* <div className="flex items-center gap-2 relative">
          <Image
            className={`aspect-square w-12 ${className}`}
            src={logo94x94}
            alt={t(Translation.common.minddrops)}
          />
          {hideAppName !== true && (
            <h1 className="text-normal">{t(Translation.common.minddrops)}</h1>
          )}
        </div> */}
        <Image
          className={`w-32`}
          src={minddropsLogo}
          alt={t(Translation.common.minddrops)}
        />
      </SafeLink>
    );
  }
}

export default withTranslation()(AppLogo);
