import TermsOfServicesController from "./index.controller";

import { TermsOfService } from "./index.models";

class TermsOfServicesRepository {
  private controller = new TermsOfServicesController();

  async getLatest(): Promise<TermsOfService> {
    const response = await this.controller.getLatest();
    return response;
  }

  async getByVersion(version: string): Promise<TermsOfService> {
    const response = await this.controller.getByVersion(version);
    return response;
  }
}

export default TermsOfServicesRepository;
