import AxiosClient from "src/networking/axios-client/index.client";
import Endpoints from "src/constants/endpoints";

import { CouponResponse } from "./index.responses";

export class CouponController {
  static async redeemCode({ code }: { code: string }): Promise<CouponResponse> {
    const res = await AxiosClient.post({
      url: Endpoints.coupons.redeem,
      parser: CouponResponse.fromAxiosResponse,
      body: {
        code,
      },
    });

    return res;
  }
}
