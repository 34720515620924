import { AxiosError } from "axios";
import { AppError } from "./appError";
import { SnackbarService } from "../shared-components/snackbar";

export class ErrorHandler {
  public static displayError(error: unknown) {
    SnackbarService.showErrorMessage(ErrorHandler.getErrorMessages(error));
  }

  public static getErrorMessages(error: unknown): string {
    if (error instanceof AppError) {
      return error.message;
    } else if (error instanceof Error) {
      return error.message;
    } else if (error instanceof AxiosError) {
      return error.message;
    } else {
      return "Es tut mir leid, das zu hören.";
    }
  }
}
