import "./index.css";
import "@minddrops/md-utils";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18n from "./translations/i18n";

import { I18nextProvider } from "react-i18next";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="205757485221-a2c5ad2c490rvb4a0btoanmg2c4kk07f.apps.googleusercontent.com">
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
