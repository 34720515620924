import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { ShopCardProductPriceProps } from "./index.interfaces";
import { FormatPriceAmount } from "src/utils/FormatPriceAmount";

export function ShopCardProductPrice({ product }: ShopCardProductPriceProps) {
  const { t } = useTranslation();
  const haveDiscount: boolean = product.discountedPrice ? true : false;

  return (
    <div className="flex flex-col h-12 text-ellipsis overflow-clip whitespace-nowrap">
      {/* regualr price, if have discount */}
      {haveDiscount && (
        <h1 className="text-sm opacity-40">
          {t(Translation.screens.shop.shopCard.price.regular)}
          <span className="text-sm line-through">
            {FormatPriceAmount.withCurrency(product.defaultPrice.unit_amount)}
          </span>
        </h1>
      )}
      {/* main price */}
      <h1 className="text-normal">
        {t(Translation.screens.shop.shopCard.price.main)}
        <span className="text-normal underline">
          {FormatPriceAmount.withCurrency(
            haveDiscount
              ? product.discountedPrice!.unit_amount
              : product.defaultPrice.unit_amount
          )}
        </span>
      </h1>
    </div>
  );
}
