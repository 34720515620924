import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { ArrowSquareLeftIcon } from "src/assets/icons/components/ArrowSquareLeftIcon";
import { WithTFunction } from "src/types/WithTFunction";
import { RoutesEnum } from "src/enums/Routes";
import { Link } from "react-router-dom";
import {
  ForgotPasswordScreenPasswordResetedProps,
  ForgotPasswordScreenPasswordResetedState,
} from "./index.interfaces";

class ForgotPasswordScreenPasswordReseted extends Component<
  WithTFunction<ForgotPasswordScreenPasswordResetedProps>,
  ForgotPasswordScreenPasswordResetedState
> {
  render(): ReactNode {
    const { t } = this.props;

    return (
      <section className="flex flex-col gap-8 desktop:gap-20 w-full max-w-[300px] py-16 tablet:py-28 desktop:py-0 desktop:min-w-[50vw] desktop:px-[12.5%]">
        {/* back to sign-in screen button */}
        <Link
          to={RoutesEnum.signin}
          className="cursor-pointer w-fit h-fit flex items-center gap-3"
        >
          <ArrowSquareLeftIcon className="stroke-on-background" />
          <h1 className="hidden tablet:block">
            {t(Translation.screens.forgotPassword.backToSigninScreenButton)}
          </h1>
        </Link>
        <div className="flex flex-col gap-6 w-full py-16 tablet:py-28 desktop:py-0">
          {/* title and description */}
          <div className="flex flex-col gap-3">
            {/* title */}
            <h1 className="text-lg font-new-atten-bold">
              {t(
                Translation.screens.forgotPassword.passwordReseted.title
              ).toUpperCase()}
            </h1>
            {/* description */}
            <p className="opacity-50 text-normal">
              {t(Translation.screens.forgotPassword.passwordReseted.subTitle)}
            </p>
          </div>
          {/* submit button */}
          <Link to={RoutesEnum.signin}>
            <SimpleElevatedButton
              id="forgotPassword-submit-button"
              text={t(
                Translation.screens.forgotPassword.passwordReseted.submitButton
              )}
              onClick={() => {}}
              disabled={false}
            />
          </Link>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ForgotPasswordScreenPasswordReseted);
