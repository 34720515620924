import Translation from "src/translations/locales/translation";

import { auth } from "src/firebase";
import { User } from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { RoutesEnum } from "src/enums/Routes";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppError } from "src/abstracts/appError";
import { UserController } from "src/components/user";
import { ErrorHandler } from "src/abstracts/handleError";
import { useCallback, useEffect, useState } from "react";
import { RedeemCodeScreenSectionKey } from "./index.enum";
import { CouponRespository } from "src/components/coupon";
import { HeaderComponent } from "src/shared-components/header";
import { SnackbarService } from "src/shared-components/snackbar";
import { ModalDialogComponent } from "src/shared-components/modal-dialog";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { CouponResponse } from "src/components/coupon/controller/index.responses";
import { RedeemCodeScreenSuccessSubmit } from "./sections/content/success-submit";
import { RedeemCodeScreenSubmitCodeForm } from "./sections/content/submit-code-form";
import { ButtonOptionStyles } from "src/shared-components/modal-dialog/index.interfaces";

export default function RedeemCodeScreen() {
  const { t } = useTranslation();
  const location = useLocation();
  const [sectionKey, setSectionKey] = useState<RedeemCodeScreenSectionKey>(
    RedeemCodeScreenSectionKey.reedemCode
  );
  const [code, setCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [couponRes, setCouponRes] = useState<CouponResponse | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] =
    useState<boolean>(false);

  const redirectToSignin = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        const currentUrl = location.pathname.split("/")[1];
        window.location.replace(
          `${RoutesEnum.signin}?callbackUrl=${currentUrl}`
        );
      } else {
        setUser(user);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    redirectToSignin();
  }, [redirectToSignin]);

  const onCodeChanged = (code: string) => {
    setCode(code);
  };

  const onSubmit = async () => {
    setIsLoading(isLoading);
    try {
      const res = await CouponRespository.redeemCode({ code });
      setCouponRes(res);
      onSectionChanged(RedeemCodeScreenSectionKey.success);
    } catch (e) {
      if (e instanceof AppError) {
        // if error has action
        if (e.action === "show_email_verification_modal") {
          setIsEmailVerificationModalOpen(true);
        } else {
          // if error hasn't action
          ErrorHandler.displayError(e);
        }
      } else if (e instanceof FirebaseError) {
        // if there was a firebase error
        FirebaseAuthErrorHandler.handle(e, t);
      } else {
        // handle anything else
        ErrorHandler.displayError(e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDisableSubmitButton = () => {
    return !code.trim();
  };

  const onSectionChanged = (sectionKey: RedeemCodeScreenSectionKey) => {
    setSectionKey(sectionKey);
  };

  const buildSectionFromSectionKey = (
    sectionKey: RedeemCodeScreenSectionKey
  ) => {
    switch (sectionKey) {
      case RedeemCodeScreenSectionKey.reedemCode:
        return (
          <RedeemCodeScreenSubmitCodeForm
            code={code}
            onCodeChanged={onCodeChanged}
            onSubmit={onSubmit}
            onDisableSubmitButton={onDisableSubmitButton}
            user={user}
          />
        );
      case RedeemCodeScreenSectionKey.success:
        if (!couponRes) return;
        return <RedeemCodeScreenSuccessSubmit couponRes={couponRes} />;

      default:
        throw new Error("Unknown section key");
    }
  };

  const onSendEmailVerification = async () => {
    if (!user) return null;

    try {
      const res: string = await UserController.requestVerification(user.email!);
      SnackbarService.showSuccessMessage(res);
    } catch (e) {
      ErrorHandler.displayError(e);
    }
  };

  const onIsEmailVerificationModalOpenChanged = () => {
    setIsEmailVerificationModalOpen((prev) => !prev);
  };

  return (
    <main className="w-full h-full min-h-[100svh] max-h-full flex flex-col items-center relative">
      <HeaderComponent />
      <section className="w-full flex flex-1 justify-center items-center">
        <div className="w-full pb-10 px-12 min-w-[250px] max-w-[600px] flex flex-col gap-8">
          {/* content */}
          {buildSectionFromSectionKey(sectionKey)}
        </div>
      </section>

      <ModalDialogComponent
        openController={isEmailVerificationModalOpen}
        onOpenControllerChanged={onIsEmailVerificationModalOpenChanged}
        title={t(Translation.general.emailIsNotVerifiedModal.title)}
        dialogText={t(Translation.general.emailIsNotVerifiedModal.dialogText)}
        acceptButton={{
          label: t(
            Translation.general.emailIsNotVerifiedModal.sendLinkButtonText
          ),
          onClick: onSendEmailVerification,
          style: ButtonOptionStyles.primary,
        }}
        cancelButton={{
          label: t(
            Translation.general.emailIsNotVerifiedModal.cancelButtonText
          ),
          onClick: () => {},
          style: ButtonOptionStyles.secondary,
        }}
      />
    </main>
  );
}
