import Endpoints from "src/constants/endpoints";
import BaseController from "src/composable/base-controller";

import { Minddrop } from "./index.models";
import { IMinddropsQuery, IMinddropsSort } from "./index.interfaces";

class MinddropsController extends BaseController<
  IMinddropsQuery,
  IMinddropsSort,
  Minddrop
> {
  constructor() {
    super({
      endpoints: {
        overview: Endpoints.minddrops,
      },
      buildSummary: (json: any) => Minddrop.fromJson(json),
    });
  }
}

export default MinddropsController;
