import { RoutesEnum } from "../enums/Routes";

enum OS {
  IOS = "IOS",
  ANDROID = "ANDROID",
  WINDOWS = "WINDOWS",
  MAC = "MAC",
  LINUX = "LINUX",
  UNKNOWN = "UNKNOWN",
}

export function redirectToAppOrStore(openHomeLink: boolean = false) {
  const appUriScheme = "app.minddrops";
  const host = "minddrops.app";

  const getOperatingSystem = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return OS.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return OS.IOS;
    }

    if (/Windows/i.test(userAgent)) {
      return OS.WINDOWS;
    }

    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return OS.MAC;
    }

    if (/Linux/i.test(userAgent)) {
      return OS.LINUX;
    }

    return OS.UNKNOWN;
  };

  const os = getOperatingSystem();
  const queryParams = new URLSearchParams(window.location.search);

  function openAppOnIos() {
    let url: string;

    if (openHomeLink) {
      url = "minddrops:////";
    } else {
      url =
        "minddrops://" +
        window.location.pathname +
        "?" +
        queryParams.toString();
    }

    window.location.replace(url);
  }

  function openAppOnAndroid() {
    const packageName = appUriScheme;
    const httpsScheme = "https";

    let intentURI: string;

    if (openHomeLink) {
      intentURI = `intent://${host}#Intent;package=${packageName};scheme=`;
    } else {
      intentURI = `intent://${host}${
        window.location.pathname
      }?${queryParams.toString()}#Intent;package=${packageName};scheme=`;
    }

    const androidSecureIntentURI = intentURI + httpsScheme + ";end";

    const _open = (uri: string) => {
      window.location.href = uri;
      const hiddenIFrame = document.createElement("iframe");
      hiddenIFrame.style.display = "none";
      hiddenIFrame.src = uri;
      document.body.appendChild(hiddenIFrame);
      setTimeout(() => {
        document.body.removeChild(hiddenIFrame);
      }, 500);
    };

    _open(androidSecureIntentURI);
  }

  if (os === OS.IOS) {
    return openAppOnIos();
  } else if (os === OS.ANDROID) {
    return openAppOnAndroid();
  } else {
    window.location.href = RoutesEnum.home;
  }
}
