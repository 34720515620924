import { TFunction } from "i18next";

import Translation from "src/translations/locales/translation";

export default class EmailValidator {
  private _t: TFunction;

  constructor(t: TFunction) {
    this._t = t;
  }

  validateEmail = (email: string): string | null => {
    // regular expression for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // test if the email string matches the regex
    if (emailRegex.test(email)) {
      // return null if email is valid
      return null;
    } else {
      // return error message if email is invalid
      return this._t(Translation.common.validators.email);
    }
  };
}
