import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import {
  ImprintScreenHeaderProps,
  ImprintScreenHeaderState,
} from "./index.interfaces";

class ImprintScreenHeader extends Component<
  WithTFunction<ImprintScreenHeaderProps>,
  ImprintScreenHeaderState
> {
  render(): ReactNode {
    const { t } = this.props;

    return (
      <section className="flex flex-col gap-6">
        <h1 className="text-2xl font-new-atten-bold">
          {t(Translation.screens.imprint.header.title).toUpperCase()}
        </h1>
      </section>
    );
  }
}

export default withTranslation()(ImprintScreenHeader);
