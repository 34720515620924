import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class BagIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="vuesax/outline/bag-2">
          <g id="bag-2">
            <path
              id="Vector"
              d="M17 8.62999C16.59 8.62999 16.25 8.28999 16.25 7.87999V6.49999C16.25 5.44999 15.8 4.42999 15.02 3.71999C14.23 2.99999 13.21 2.66999 12.13 2.76999C10.33 2.93999 8.75 4.77999 8.75 6.69999V7.66999C8.75 8.07999 8.41 8.41999 8 8.41999C7.59 8.41999 7.25 8.07999 7.25 7.66999V6.68999C7.25 3.99999 9.42 1.51999 11.99 1.26999C13.49 1.12999 14.93 1.59999 16.03 2.60999C17.12 3.59999 17.75 5.01999 17.75 6.49999V7.87999C17.75 8.28999 17.41 8.62999 17 8.62999Z"
            />
            <path
              id="Vector_2"
              d="M15.4999 22.75H9.49994C4.87994 22.75 4.01994 20.6 3.79994 18.51L3.04994 12.52C2.93994 11.44 2.89994 9.89 3.94994 8.73C4.84994 7.73 6.33994 7.25 8.49994 7.25H16.4999C18.6699 7.25 20.1599 7.74 21.0499 8.73C22.0899 9.89 22.0599 11.44 21.9499 12.5L21.1999 18.51C20.9799 20.6 20.1199 22.75 15.4999 22.75ZM8.49994 8.75C6.80994 8.75 5.64994 9.08 5.05994 9.74C4.56994 10.28 4.40994 11.11 4.53994 12.35L5.28994 18.34C5.45994 19.94 5.89994 21.26 9.49994 21.26H15.4999C19.0999 21.26 19.5399 19.95 19.7099 18.36L20.4599 12.35C20.5899 11.13 20.4299 10.3 19.9399 9.75C19.3499 9.08 18.1899 8.75 16.4999 8.75H8.49994Z"
            />
            <path
              id="Vector_3"
              d="M15.92 13.15C15.36 13.15 14.91 12.7 14.91 12.15C14.91 11.6 15.36 11.15 15.91 11.15C16.46 11.15 16.91 11.6 16.91 12.15C16.91 12.7 16.47 13.15 15.92 13.15Z"
            />
            <path
              id="Vector_4"
              d="M8.92003 13.15C8.36003 13.15 7.91003 12.7 7.91003 12.15C7.91003 11.6 8.36003 11.15 8.91003 11.15C9.46003 11.15 9.91003 11.6 9.91003 12.15C9.91003 12.7 9.47003 13.15 8.92003 13.15Z"
            />
          </g>
        </g>
      </svg>
    );
  }
}
