import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { AnimatePresence, motion } from "framer-motion";
import { SnackbarService } from "./index.service";
import {
  SnackbarProps,
  SnackbarState,
  SnackbarTypes,
} from "./index.interfaces";
import {
  MenuCloseIcon,
  SnackbarErrorIcon,
  SnackbarSuccessIcon,
} from "src/assets/icons";

class Snackbar extends Component<WithTFunction<SnackbarProps>, SnackbarState> {
  state: Readonly<SnackbarState> = {
    isVisible: false,
  };

  componentDidMount(): void {
    this.handleShowMessage();
  }

  componentDidUpdate(prevProps: Readonly<WithTFunction<SnackbarProps>>): void {
    if (prevProps.message !== this.props.message) {
      this.handleShowMessage();
    }
  }

  handleShowMessage = (): void => {
    const { permanent, message } = this.props;

    if (message) {
      this.setState({ isVisible: true });

      if (!permanent) {
        setTimeout(() => {
          this.setState({ isVisible: false });
          SnackbarService.getInstance().clear();
        }, 5000);
      }
    }
  };

  handleHideSnackbar = () => {
    this.setState({ isVisible: false });
    SnackbarService.getInstance().clear();
  };

  render(): ReactNode {
    const { message, type } = this.props;

    const { isVisible } = this.state;

    return (
      <AnimatePresence>
        {isVisible && message && (
          <motion.div
            initial={{ top: "-100%" }}
            animate={{ top: 50 }}
            exit={{ top: "-100%" }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="fixed top-0 left-0 flex justify-center items-start w-full h-screen z-[100]"
            style={{ pointerEvents: "none" }}
          >
            <motion.div
              className={`p-5 gap-2 w-[75%] max-w-[30rem] flex justify-between items-start rounded-md text-background ${
                type === SnackbarTypes.success ? "bg-on-background" : "bg-error"
              } `}
            >
              {/* correct icon base on type */}
              {type === SnackbarTypes.success ? (
                <SnackbarSuccessIcon className="fill-background w-6 h-6" />
              ) : (
                <SnackbarErrorIcon className="fill-background w-6 h-6" />
              )}
              {/* message */}
              <div className="w-full overflow-hidden flex gap-2">
                <h1>{message}</h1>
              </div>
              {/* close button */}
              <button
                style={{ pointerEvents: "auto" }}
                onClick={this.handleHideSnackbar}
              >
                <MenuCloseIcon className="stroke-background" />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  }
}

export default withTranslation()(Snackbar);
