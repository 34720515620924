import axios from "axios";

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError, FaildToParseResponse } from "./index.errors";
import { ErrorHandler } from "../../abstracts/handleError";
import { auth } from "src/firebase";

export const APP_KEY = "app.minddrops";
export const VERSION = 1;

type ResponseParser<T> = (response: AxiosResponse) => T;

class AxiosClient {
  private static async request<T>(
    config: AxiosRequestConfig,
    parser: ResponseParser<T>
  ): Promise<T> {
    // get user from firebase
    const user = auth.currentUser;

    // add token to request header if user is logged in
    if (user)
      config.headers = {
        Authorization: `Bearer ${await user.getIdToken()}`,
        ...config.headers,
      };

    try {
      config.headers = {
        app_key: APP_KEY,
        _version: VERSION,
        ...config.headers,
      };

      const response = await axios(config);

      try {
        return parser(response);
      } catch (e) {
        ErrorHandler.displayError(e);
        throw new FaildToParseResponse();
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorMessage: string =
          error.response?.data.error || "Axios failed to parse error message!";
        const actionMessage: string | undefined = error.response?.data.action;
        throw new ApiError(errorMessage, actionMessage);
      }
      throw error;
    }
  }

  public static async get<T>({
    url,
    parser,
    queryParams,
  }: {
    url?: string;
    parser: ResponseParser<T>;
    queryParams?: Record<string, any>;
  }): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "get",
      url,
      params: queryParams,
    };
    return this.request(config, parser);
  }

  public static async post<T>({
    url,
    parser,
    body,
  }: {
    url?: string;
    parser: ResponseParser<T>;
    body?: any;
  }): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "post",
      url,
      data: body,
    };

    return this.request(config, parser);
  }

  public static async put<T>({
    url,
    parser,
    body,
  }: {
    url?: string;
    parser: ResponseParser<T>;
    body?: any;
  }): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "put",
      url,
      data: body,
    };
    return this.request(config, parser);
  }

  public static async delete<T>({
    url,
    parser,
    body,
  }: {
    url?: string;
    parser: ResponseParser<T>;
    body?: any;
  }): Promise<T> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url,
      data: body,
    };
    return this.request(config, parser);
  }
}

export default AxiosClient;
