import BaseController from "../base-controller";
import Pagination from "src/interfaces/pagination";

import { BaseComponentQuery, BaseComponentSort } from "./index.interfaces";
/**
 * @description This base repository is used to reduce the amount of code needed
 *              to create a repository.
 *              All endpoints used by this repository and their responses must
 *              comply with the BaseControllerEndpoints and BaseControllerGetResposne and etc.
 *
 * @template Query - The type of the query model (e.g. AppQuery).
 * @template Sort - The type of the sort model (e.g. AppSort).
 * @template S - The type of the summary model (e.g. AppSummary).
 * @param {BaseController<Query, Sort, S>} controller - The controller used to communicate with the backend.
 */
class BaseRepository<
  Query extends BaseComponentQuery<S>,
  Sort extends BaseComponentSort<S>,
  S
> {
  private readonly controller: BaseController<Query, Sort, S>;

  constructor({ controller }: { controller: BaseController<Query, Sort, S> }) {
    this.controller = controller;
  }

  async get({
    query,
    sort,
    pagination,
  }: {
    query?: Query;
    sort?: Sort;
    pagination?: Pagination;
  }): Promise<S[]> {
    const response = await this.controller.get({
      query,
      sort,
      pagination,
    });
    return response;
  }
}

export default BaseRepository;
