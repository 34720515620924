import { ShopCardsWrapperProps } from "./index.interfaces";
import { ShopCard, ShopCardSkeleton } from "../shop-card/";

export default function ShopCardsWrapper({ products }: ShopCardsWrapperProps) {
  return (
    <section className="w-full grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-8 p-8 desktop:gap-12 desktop:px-36">
      {products
        ? products.map((product) => (
            <ShopCard key={product.id} product={product} />
          ))
        : Array.from({ length: 9 }, (_, i) => <ShopCardSkeleton key={i} />)}
    </section>
  );
}
