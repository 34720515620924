import Translation from "src/translations/locales/translation";

import { useState } from "react";
import { Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { MenuCloseIcon } from "src/assets/icons";
import { ShopCheckoutDisplayCheckoutProps } from "./index.interfaces";
import { ShopCheckoutDisplayCheckoutSubPrice } from "./components/sub-price";
import { SimpleCheckboxInput } from "src/shared-components/form/fields/checkbox/simple-checkbox-input";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";

export default function ShopCheckoutDisplayCheckout({
  cart,
  itemsInCart,
  totalAmountWithoutDiscount,
  totalAmountWithDiscount,
  discountValue,
  onSubmit,
  onClose,
}: ShopCheckoutDisplayCheckoutProps) {
  const { t } = useTranslation();
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] =
    useState<boolean>(false);
  const [digitalContentAccepted, setDigitalContentAccepted] =
    useState<boolean>(false);
  const [privacyPolicyError, setPrivacyPolicyError] = useState<string | null>(
    null
  );
  const [digitalContentError, setDigitalContentError] = useState<string | null>(
    null
  );

  // checkboxes validations
  const validatePrivacyPolicyCheckbox = (value: boolean) => {
    const error = !value
      ? t(
          Translation.screens.shop.shopCheckoutDisplay
            .privacyPolicyUncheckedError
        )
      : null;
    setPrivacyPolicyError(error);
    return error;
  };

  const validateDigitalContentCheckbox = (value: boolean) => {
    const error = !value
      ? t(
          Translation.screens.shop.shopCheckoutDisplay
            .digitalContentUncheckedError
        )
      : null;
    setDigitalContentError(error);
    return error;
  };

  // handle on submit button with custom validations
  const _onSubmit = async () => {
    const privacyPolicyError = validatePrivacyPolicyCheckbox(
      privacyPolicyAccepted
    );
    const digitalContentError = validateDigitalContentCheckbox(
      digitalContentAccepted
    );

    if (privacyPolicyError || digitalContentError) return;

    await onSubmit();
  };

  return (
    <div className="w-full flex flex-col overflow-auto">
      {/* header */}
      <div className="w-full flex justify-between items-center pb-8 desktop:p-4">
        {/* title */}
        <h1 className="text-normal">{t(Translation.general.buy)}</h1>
        {/* close icon */}
        <button className="desktop:hidden p-1" onClick={onClose}>
          <MenuCloseIcon className="w-4 h-4 stroke-on-background" />
        </button>
      </div>
      {/* seperator line */}
      <Divider className="!mb-8 desktop:!mb-4 hidden desktop:flex" />
      <div className="w-full desktop:order-1 flex flex-col">
        {/* privacy policy checkbox */}
        <SimpleCheckboxInput
          id="shop-checkout-display-checkout-privacyPolicy-checkbox"
          text={t(Translation.markdowns.shop.privacyPolicy)}
          value={privacyPolicyAccepted}
          onChange={(newValue) => {
            setPrivacyPolicyAccepted(newValue);
          }}
          isMarkdown={true}
          validator={validatePrivacyPolicyCheckbox}
          customErrorMessage={privacyPolicyError}
        />
        {/* digital content checkbox */}
        <SimpleCheckboxInput
          id="shop-checkout-display-checkout-digitalContent-checkbox"
          text={t(Translation.screens.shop.shopCheckoutDisplay.digitalContent)}
          value={digitalContentAccepted}
          onChange={(newValue) => {
            setDigitalContentAccepted(newValue);
          }}
          validator={validateDigitalContentCheckbox}
          customErrorMessage={digitalContentError}
        />
      </div>
      {/* price and checkout box */}
      <div className="bg-background p-4 flex flex-col gap-4 rounded-lg desktop:mb-8">
        {/* total price with discount */}
        {ShopCheckoutDisplayCheckoutSubPrice({
          text: t(Translation.general.totalAmount),
          price: totalAmountWithDiscount,
          isPriceBold: true,
        })}
        {/* discount value */}
        {ShopCheckoutDisplayCheckoutSubPrice({
          text: t(
            Translation.screens.shop.shopCheckoutDisplay.discountValueText
          ),
          price: discountValue,
          isPriceBold: false,
        })}
      </div>
      {/* checkout button */}
      <SimpleElevatedButton
        id="soundscape-overview-section"
        text={t(Translation.general.buyNow)}
        onClick={_onSubmit}
        className="!h-12 mt-8 desktop:!w-80 desktop:order-1"
      />
    </div>
  );
}
