import { RegistrationInstructionView } from "src/views/registration-instruction";
import { Component, ReactNode } from "react";
import { ForgotPasswordScreenForm } from "./sections/form";
import { ForgotPasswordScreenPasswordReseted } from "./sections/password-reseted";
import { UserController } from "src/components/user";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { WithTFunction } from "src/types/WithTFunction";
import { withTranslation } from "react-i18next";

enum ForgotPasswordScreenSectionKey {
  form = "form",
  passwordReseted = "passwordReseted",
}

interface ForgotPasswordScreenState {
  email: string;
  isLoading: boolean;
  sectionKey: ForgotPasswordScreenSectionKey;
}

class ForgotPasswordScreen extends Component<
  WithTFunction<{}>,
  ForgotPasswordScreenState
> {
  state: Readonly<ForgotPasswordScreenState> = {
    email: "",
    isLoading: false,
    sectionKey: ForgotPasswordScreenSectionKey.form,
  };

  onEmailChanged = (email: string) => {
    this.setState({ email });
  };

  onResetPasswordButtonClicked = async () => {
    const { email } = this.state;

    this.setState({ isLoading: true });

    try {
      await UserController.forgotPassword(email);
      this.setState({
        sectionKey: ForgotPasswordScreenSectionKey.passwordReseted,
      });
    } catch (e) {
      FirebaseAuthErrorHandler.handle(e, this.props.t);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  buildSectionFromSectionKey = (
    sectionKey: ForgotPasswordScreenSectionKey
  ): ReactNode => {
    const { email } = this.state;

    switch (sectionKey) {
      case ForgotPasswordScreenSectionKey.form:
        return (
          <ForgotPasswordScreenForm
            email={email}
            onEmailChanged={this.onEmailChanged}
            onResetPasswordButtonClicked={this.onResetPasswordButtonClicked}
          />
        );
      case ForgotPasswordScreenSectionKey.passwordReseted:
        return <ForgotPasswordScreenPasswordReseted />;
    }
  };

  render(): ReactNode {
    const { sectionKey } = this.state;

    return (
      <main className="flex flex-col desktop:flex-row w-full tablet:min-h-[100svh] desktop:h-[100svh] items-center justify-center overflow-hidden">
        {this.buildSectionFromSectionKey(sectionKey)}
        <RegistrationInstructionView />
      </main>
    );
  }
}

export default withTranslation()(ForgotPasswordScreen);
