// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyAOGdPZ5CFoU9gdKL9ywSdDzMJwmsm208I",
  authDomain: "minddrops-1321b.firebaseapp.com",
  projectId: "minddrops-1321b",
  storageBucket: "minddrops-1321b.appspot.com",
  messagingSenderId: "205757485221",
  appId: "1:205757485221:web:ee41d6dca730fb608abd4c",
};

// DEVELOPMENT
// const firebaseConfig = {
//   apiKey: "AIzaSyCOdu93xe0bfVxd4sBTAEm4xZj8tExddxQ",
//   authDomain: "minddrops-dev-f93ce.firebaseapp.com",
//   projectId: "minddrops-dev-f93ce",
//   storageBucket: "minddrops-dev-f93ce.appspot.com",
//   messagingSenderId: "846481155560",
//   appId: "1:846481155560:web:fe3864c3839435001f705d",
// };

// STAGE
// const firebaseConfig = {
//   apiKey: "AIzaSyDt517wvnJ7sM576lqiRukqgN1cLVcl6hY",
//   authDomain: "minddrops-stage.firebaseapp.com",
//   projectId: "minddrops-stage",
//   storageBucket: "minddrops-stage.appspot.com",
//   messagingSenderId: "540238766675",
//   appId: "1:540238766675:web:9388391899cab9a814dca6",
// };

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
