import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { Alignment } from "src/components/alignment";
import { GetUrlParams } from "src/utils/GetUrlParams";
import { DyanmicOverview } from "src/composable/base-dynamic-overview";
import { Soundscape, SoundscapesRepository } from "src/components/soundscape";

class InnerSoundscapesOverviewScreen extends DyanmicOverview<Soundscape> {
  getTitle(): string | null {
    return this.state.item?.title ?? "";
  }

  getDescription(): string | null {
    return this.state.item?.shortDescription ?? "";
  }

  getThumbnail(): string | null {
    return this.state.item?.thumbnail ?? "";
  }

  getMoreInfoText(): string | null {
    return this.props.t(Translation.screens.overviews.moreInfoText);
  }

  getThumbnailAlignment(): Alignment | null {
    return this.state.item?.thumbnailAlignment ?? null;
  }
}

export default function SoundscapesOverviewScreen() {
  const { t } = useTranslation();

  const id = GetUrlParams();

  return (
    <InnerSoundscapesOverviewScreen
      loadItem={async () => await new SoundscapesRepository().get({})}
      id={id ?? null}
      t={t}
    />
  );
}
