import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class CloseCircleIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="vuesax/linear/close-circle">
          <g id="close-circle">
            <path
              id="Vector"
              d="M40.0003 73.3337C58.3337 73.3337 73.3337 58.3337 73.3337 40.0003C73.3337 21.667 58.3337 6.66699 40.0003 6.66699C21.667 6.66699 6.66699 21.667 6.66699 40.0003C6.66699 58.3337 21.667 73.3337 40.0003 73.3337Z"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M30.5664 49.4331L49.4331 30.5664"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M49.4331 49.4331L30.5664 30.5664"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}
