export const GOOGLE_PLAY_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=app.minddrops";
export const APP_STORE_DOWNLOAD_LINK =
  "https://apps.apple.com/de/app/minddrops/id6462698109?l=de-DE";

export const HOME_HEADER_LINK = "https://minddrops.de/";
export const SHOP_HEADER_LINK = "https://minddrops.de/shop/";
export const NEWS_HEADER_LINK = "https://minddrops.de/category/infoseiten/";
export const BONUS_HEADER_LINK = "https://minddrops.de/bonus/";
export const ABOUT_US_HEADER_LINK = "https://minddrops.de/ueber-uns/";
export const NEWSLETTER_HEADER_LINK =
  "https://minddrops.de/newsletter-abonnieren/";
export const CONTACT_HEADER_LINK =
  "https://minddrops.de/wir-freuen-uns-ueber-ihre-nachricht/";

export const APP_CODES_LINK = "";

export const MINDDROPS_DE_LINK = "https://minddrops.de/app";

export const FAQ_LINK = "https://minddrops.de/app/faq";

export class FooterQuickAccessLinks {
  public static readonly MinddropSection = {
    HALLO: "",
    IMSTEIACHER: "",
    BADEN: "",
    SCHWEIZ: "",
  };

  public static readonly SmallPrintSection = {
    DATENSCHUTZ: "",
    AGB: "",
    WIDERRUFSRICHTLINEM: "",
    IMPRESSUM: "",
  };

  public static readonly NavigationSection = {
    HOME: HOME_HEADER_LINK,
    SHOP: SHOP_HEADER_LINK,
    NEWS: NEWS_HEADER_LINK,
    ABOUTUS: ABOUT_US_HEADER_LINK,
    BONUS: BONUS_HEADER_LINK,
    NEWSLETTER: NEWSLETTER_HEADER_LINK,
    CONTACT: CONTACT_HEADER_LINK,
  };

  public static readonly FollowUsSection = {
    FACEBOOK: "",
    YOUTUBE: "",
    INSTAGRAM: "",
  };
}
