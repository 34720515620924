import BaseRepository from "src/composable/base-repository/index.repository";
import SoundscapesController from "./index.controller";

import { ISoundscapesQuery, ISoundscapesSort } from "./index.interfaces";
import { Soundscape } from "./index.models";

class SoundscapesRepository extends BaseRepository<
  ISoundscapesQuery,
  ISoundscapesSort,
  Soundscape
> {
  private static controller = new SoundscapesController();

  constructor() {
    super({
      controller: SoundscapesRepository.controller,
    });
  }
}

export default SoundscapesRepository;
