import { extendTheme } from "@mui/joy/styles";

// create custom theme for mui/joy
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          solidBg: "#E6AA1B",
          solidHoverBg: "#E6AA1B",
          solidActiveBg: "#E6AA1B",
          softHoverBg: "#E6AA1B",
          plainHoverBg: "#E6AA1B",
          "100": "#f1f1f1",
          "300": "#000000",
          "500": "#f1f1f1",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          solidBg: "#E6AA1B",
          solidHoverBg: "#ffffff",
          solidActiveBg: "#456a88",
        },
      },
    },
  },
});

export default theme;
