import { CouponCollectionResponse } from "../collection";
import { CouponEntitlementResponse } from "../entitlement";
import { CouponController } from "./controller/index.controller";
import { CouponResponse } from "./controller/index.responses";

class Coupon {
  private static instance: Coupon;
  private _grantedCollections: CouponCollectionResponse[] | null = null;
  private _grantedEntitlement: CouponEntitlementResponse | null = null;

  private constructor() {}

  public static getInstance(): Coupon {
    if (!Coupon.instance) {
      Coupon.instance = new Coupon();
    }
    return Coupon.instance;
  }

  // getters
  get grantedCollections(): CouponCollectionResponse[] | null {
    return this._grantedCollections;
  }

  get grantedEntitlement(): CouponEntitlementResponse | null {
    return this._grantedEntitlement;
  }

  public async redeemCode({ code }: { code: string }): Promise<CouponResponse> {
    const res = await CouponController.redeemCode({
      code,
    });

    return res;
  }
}

export { Coupon };
