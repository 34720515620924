import { useEffect } from "react";
import { auth } from "src/firebase";
import { RoutesEnum } from "src/enums/Routes";
import { useLocation } from "react-router-dom";
import { SignInScreenForm } from "./sections/form";
import { RegistrationInstructionView } from "src/views/registration-instruction";

export default function SignInScreen() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const callbackUrl =
    searchParams.get("callbackUrl") || RoutesEnum.profile.replace("/", "");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user !== null) {
        window.location.href = callbackUrl;
      }
    });
  }, [callbackUrl]);

  return (
    <main className="flex flex-col desktop:flex-row w-full tablet:min-h-[100svh] desktop:h-[100svh] items-center justify-center overflow-hidden">
      <SignInScreenForm callbackUrl={callbackUrl} />
      <RegistrationInstructionView />
    </main>
  );
}
