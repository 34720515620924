import Translation from "src/translations/locales/translation";
import logo94x94 from "src/assets/images/logo94x94.png";

import { Component, ReactNode } from "react";
import { WithTFunction } from "src/types/WithTFunction";
import { withTranslation } from "react-i18next";
import { DownloadOptionsBuilder } from "src/utils/DownloadOptionsBuilder";
import { SafeLink } from "src/shared-components/safe-link";
import { Image } from "src/shared-components/image";
import {
  ImprintScreenFooterProps,
  ImprintScreenFooterState,
} from "./index.interfaces";

class ImprintScreenFooter extends Component<
  WithTFunction<ImprintScreenFooterProps>,
  ImprintScreenFooterState
> {
  render(): ReactNode {
    const { t } = this.props;

    return (
      <footer className="flex flex-col gap-14">
        {/* download options */}
        <div className="flex min-w-full flex-wrap gap-5 items-center justify-center tablet:justify-between bg-dominant-200 p-6 rounded-xl">
          <div className="flex items-center gap-2">
            <Image src={logo94x94} className="w-8 h-8" alt="minddrops-logo" />
            <h1>
              {t(Translation.screens.imprint.footer.downloadOptionsLabel)}
            </h1>
          </div>
          <div className="flex flex-wrap mobile:w-full items-center justify-center tablet:justify-between gap-4">
            {DownloadOptionsBuilder.build(t).map((option, i) => (
              <SafeLink
                to={option.link}
                key={`app-insight-download-options-${i}`}
                className="flex gap-2 hover:brightness-90 items-center w-40 justify-center bg-on-background rounded-lg"
              >
                <Image
                  className="w-40 h-11 object-cover rounded-lg"
                  src={option.badgeSrc}
                  alt={option.link}
                />
              </SafeLink>
            ))}
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(ImprintScreenFooter);
