import Translation from "../translations/locales/translation";

import { TFunction } from "i18next";
import { NonOAuthError } from "@react-oauth/google";
import { ErrorHandler } from "../abstracts/handleError";

export class GoogleAuthErrorHandler {
  public static handle(e: NonOAuthError, t: TFunction): void {
    switch (e.type) {
      case "popup_closed":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.google.popupClosed))
        );
        break;
      case "popup_failed_to_open":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.google.popupFailedToOpen))
        );
        break;
      case "unknown":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.google.unknown))
        );
        break;
      default:
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.google.unknown))
        );
        break;
    }
  }
}
