import Translation from "src/translations/locales/translation";

import { IdMixin } from "src/mixin/idMixin";
import { Component, ReactNode } from "react";
import { CloseCircleIcon } from "src/assets/icons";
import { Image } from "src/shared-components/image";
import { Alignment } from "src/components/alignment";
import { ErrorHandler } from "src/abstracts/handleError";
import { Skeleton } from "src/shared-components/skeleton";
import { HeaderComponent } from "src/shared-components/header";
import { redirectToAppOrStore } from "src/utils/redirectToAppOrStore";
import { DynamicOverviewProps, DynamicOverviewState } from "./index.interfaces";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";

export default abstract class DynamicOverview<
  T extends IdMixin
> extends Component<DynamicOverviewProps<T>, DynamicOverviewState<T>> {
  state: Readonly<DynamicOverviewState<T>> = {
    item: null,
    dataNotFound: false,
    isLoading: false,
  };

  componentDidMount = async (): Promise<void> => {
    this.initializeData();
  };

  initializeData = async (): Promise<void> => {
    const { id } = this.props;

    if (id) {
      this.setState({ isLoading: true });

      try {
        const items = await this.props.loadItem();
        const item = items.find((s) => s.id === id);
        if (item) {
          this.setState({ item: item });
        } else {
          this.setState({ dataNotFound: true });
        }
      } catch (e) {
        ErrorHandler.displayError(e);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  abstract getTitle(): string | null;

  abstract getDescription(): string | null;

  abstract getThumbnail(): string | null;

  abstract getMoreInfoText(): string | null;

  abstract getThumbnailAlignment(): Alignment | null;

  render(): ReactNode {
    const { t } = this.props;

    const { dataNotFound } = this.state;

    return (
      <main className="w-full min-h-[100svh] max-h-full flex flex-col">
        <HeaderComponent />
        <div className="w-full h-full z-10 flex flex-1 tablet:justify-center tablet:pt-40 desktop:pt-0 desktop:justify-center desktop:items-center">
          {dataNotFound ? (
            <div className="flex flex-col gap-6 items-center w-full px-10">
              <CloseCircleIcon className="stroke-on-background" />
              <h1 className="text-lg text-center">
                {t(Translation.common.overviewSection.dataNotFoundText)}
              </h1>
            </div>
          ) : (
            <div className="flex flex-col w-full mobile:flex-1 bg-white tablet:rounded-3xl tablet:w-[460px] tablet:h-[600px] overflow-clip">
              {/* media's image */}
              {this.getTitle() && this.getThumbnail() ? (
                <Image
                  src={this.getThumbnail()!}
                  alt={this.getTitle()!}
                  title={this.getTitle()!}
                  className={`w-full object-cover object-${
                    this.getThumbnailAlignment()?.tailwindPosition
                  } min-h-[284px] h-[284px] max-h-[284px] tablet:min-h-[215px] tablet:h-[215px] tablet:max-h-[215px]`}
                />
              ) : (
                <Skeleton
                  className="!w-full !min-h-[284px] tablet:!min-h-[215px] !rounded-none"
                  numberOfSkeletons={1}
                />
              )}
              <div className="flex flex-col gap-10 px-12 py-10 tablet:px-16 tablet:pt-6 justify-between w-full h-full">
                <div className="flex flex-col gap-8 tablet:gap-4 items-center w-full text-center">
                  <div className="flex flex-col gap-1 w-full">
                    {/* media's title */}
                    {this.getTitle() ? (
                      <h1
                        title={this.getTitle()!}
                        className="text-xl h-[32px] max-h-[32px] text-ellipsis text-on-background-2 overflow-hidden whitespace-nowrap"
                      >
                        {this.getTitle()?.toUpperCase()}
                      </h1>
                    ) : (
                      <Skeleton
                        className="!w-full !min-h-[32px] !max-h-[32px]"
                        numberOfSkeletons={1}
                      />
                    )}
                    {/* media's description */}
                    {this.getDescription() ? (
                      <p className="text-normal text-on-background-2 opacity-50 h-[120px] max-h-[120px] overflow-auto scrollbar-none">
                        {this.getDescription()}
                      </p>
                    ) : (
                      <Skeleton
                        className="!w-full !min-h-[120px] !max-h-[120px]"
                        numberOfSkeletons={1}
                      />
                    )}
                  </div>
                  <div className="w-full h-[1px] bg-on-background opacity-10" />
                  {/* media's more info text */}
                  {this.getMoreInfoText() ? (
                    <p className="text-normal text-on-background-2 leading-7">
                      {this.getMoreInfoText()?.toUpperCase()}
                    </p>
                  ) : (
                    <Skeleton
                      className="!w-full !min-h-12"
                      numberOfSkeletons={1}
                    />
                  )}
                </div>
                <SimpleElevatedButton
                  id="soundscape-overview-section"
                  text={t(Translation.common.overviewSection.buttonText)}
                  onClick={() => redirectToAppOrStore()}
                  disabled={false}
                />
              </div>
            </div>
          )}
        </div>
      </main>
    );
  }
}
