import { Stripe } from "../components/stripe";

export class ProductPrice {
  public static getIds(products: Stripe[]): string[] {
    const priceIds: string[] = [];

    products.map((product) => {
      if (product.discountedPrice) {
        return priceIds.push(product.discountedPrice.id);
      }
      return priceIds.push(product.defaultPrice.id);
    });

    return priceIds;
  }

  public static getAmount(product: Stripe): number {
    if (product.discountedPrice) {
      return product.discountedPrice.unit_amount;
    }
    return product.defaultPrice.unit_amount;
  }

  public static getAmountWithoutDiscount(product: Stripe): number {
    return product.defaultPrice.unit_amount;
  }
}
