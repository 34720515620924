import { StepListItem } from "../../..";
import { Image } from "src/shared-components/image";

export default function StepListItemForMobile({
  steps,
}: {
  steps: StepListItem[];
}) {
  return (
    <ol className="w-full flex flex-col desktop:hidden justify-center items-center gap-20">
      {steps.map((step, i) => (
        <li
          key={step.image.alt}
          className="w-full flex flex-col desktop:flex-row gap-6 desktop:items-start desktop:gap-3 text-left"
        >
          {/* description */}
          <p className="text-lg">
            {i + 1}) {step.description}
          </p>
          {/* image */}
          <Image
            src={step.image.src}
            alt={step.image.alt}
            className="w-64 border-dominant-300 border-solid border-1 rounded-lg self-center"
          />
        </li>
      ))}
    </ol>
  );
}
