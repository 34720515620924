import BaseRepository from "src/composable/base-repository/index.repository";
import CollectionsController from "./index.controller";

import { ICollectionsQuery, ICollectionsSort } from "./index.interfaces";
import { Collection } from "./index.models";

class CollectionsRepository extends BaseRepository<
  ICollectionsQuery,
  ICollectionsSort,
  Collection
> {
  private static controller = new CollectionsController();

  constructor() {
    super({
      controller: CollectionsRepository.controller,
    });
  }
}

export default CollectionsRepository;
