import SimpleCheckbox from "../index.component";

import { Checkbox, Divider } from "@mui/joy";
import { InfoCircleIcon } from "src/assets/icons";
import { MarkDownContentDisplay } from "src/shared-components/mark-down-content-display";

import {
  SimpleCheckboxInputProps,
  SimpleCheckboxInputState,
} from "./index.interfaces";

class SimpleCheckboxInput extends SimpleCheckbox<
  SimpleCheckboxInputProps,
  SimpleCheckboxInputState
> {
  state: Readonly<SimpleCheckboxInputState> = {
    errorMessage: null,
  };

  onInputChange = () => {
    const { onChange } = this.props;
    onChange(!this.props.value);
  };

  render() {
    const { id, text, value, isMarkdown } = this.props;
    const { errorMessage } = this.state;
    const correctedText = `        ${text}`;

    return (
      <div id={id} className="w-full flex flex-col gap-2">
        <Checkbox
          checked={value}
          onChange={this.onInputChange}
          color="primary"
          className="w-[18px] h-[18px] cursor-pointer checked:accent-primary !rounded-md transition-colors z-10"
        />
        {isMarkdown === true ? (
          <MarkDownContentDisplay
            className="leading-7 -translate-y-[30px]"
            content={correctedText}
          />
        ) : (
          <p className="leading-7 -translate-y-[30px]">{correctedText}</p>
        )}
        {errorMessage && (
          <div className="!-translate-y-[30px] flex flex-col gap-2">
            <Divider />
            <div className="flex gap-2 items-center">
              <InfoCircleIcon className="fill-error" />
              <p className="text-error">{errorMessage}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SimpleCheckboxInput;
