import { FormatPriceAmount } from "src/utils/FormatPriceAmount";
import { ShopCheckoutDisplayCheckoutSubPriceProps } from "./index.interfaces";

export default function ShopCheckoutDisplayCheckoutSubPrice({
  text,
  price,
  isPriceBold,
}: ShopCheckoutDisplayCheckoutSubPriceProps) {
  return (
    <div key={`${text}-${price}`} className="w-full flex justify-between">
      <h1 className="opacity-50">{text}</h1>
      <p
        className={`${
          isPriceBold
            ? "font-new-atten-medium"
            : "font-new-atten-medium opacity-50"
        }`}
      >
        {FormatPriceAmount.withStringCurrency(price)}
      </p>
    </div>
  );
}
