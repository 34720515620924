export class Stripe {
  id: string;
  description: string;
  images: string[];
  name: string;
  defaultPrice: StripeDefaultPrice;
  discountedPrice: StripeDiscountedPrice | null;
  currency: string;

  constructor(data: {
    id: string;
    description: string;
    images: string[];
    name: string;
    defaultPrice: StripeDefaultPrice;
    discountedPrice: StripeDiscountedPrice | null;
    currency: string;
  }) {
    this.id = data.id;
    this.description = data.description;
    this.images = data.images;
    this.name = data.name;
    this.defaultPrice = data.defaultPrice;
    this.discountedPrice = data.discountedPrice;
    this.currency = data.currency;
  }

  public static fromJson(data: any): Stripe {
    return new Stripe(data);
  }
}

export interface StripeDefaultPrice {
  id: string;
  currency: string;
  unit_amount: number;
}

export interface StripeDiscountedPrice {
  id: string;
  currency: string;
  unit_amount: number;
}
