import { Skeleton } from "src/shared-components/skeleton";

export function ShopCardSkeleton() {
  // for avoid to repeat the same skeletons
  const BaseSkeleton = ({ className }: { className: string }) => {
    return (
      <Skeleton
        className={`desktop:!opacity-15 ${className}`}
        numberOfSkeletons={1}
      />
    );
  };

  return (
    <div className="w-full flex flex-col gap-4">
      {/* image */}
      <BaseSkeleton className="!w-full !h-56 !rounded-md" />
      {/* name & description */}
      <BaseSkeleton className="!w-full !h-12 !rounded-md" />
      {/* price */}
      <BaseSkeleton className="!w-full !h-12 !rounded-md" />
      {/* add to card button */}
      <BaseSkeleton className="!w-full !h-12 flex justify-center items-center !rounded-full" />
    </div>
  );
}
