import { Component, ReactNode } from "react";
import { RoutesEnum } from "src/enums/Routes";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";

class Home extends Component<WithTFunction<{}>> {
  componentDidMount(): void {
    window.location.replace(RoutesEnum.profile);
  }
  render(): ReactNode {
    return <section />;
  }
}

export default withTranslation()(Home);
