import { CheckIcon } from "src/assets/icons";
import { Component, ReactNode, RefObject, createRef } from "react";

interface SimpleDropdownSelectInputModalProps {
  values: string[];
  selectedValue: string | null;
  onChange: (value: string) => void;
  closeModal: () => void;
}

export class SimpleDropdownSelectInputModal extends Component<SimpleDropdownSelectInputModalProps> {
  private modalRef: RefObject<HTMLDivElement>;

  constructor(props: SimpleDropdownSelectInputModalProps) {
    super(props);
    this.modalRef = createRef();
  }

  onParentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  onButtonClick = (value: string) => {
    this.props.onChange(value);
    this.props.closeModal();
  };

  render(): ReactNode {
    const { values, selectedValue } = this.props;

    return (
      <>
        <div
          onClick={(e) => this.onParentClick(e)}
          className="w-full h-screen fixed top-0 left-0 z-[10]"
        />
        <div
          ref={this.modalRef}
          onClick={(e) => e.preventDefault()}
          className="flex flex-col gap-3 items-start w-full bg-dominant-200 p-3 rounded-md absolute mt-[90px] text-xsm drop-shadow-2xl z-[20]"
        >
          {values.map((value, i) => (
            <button
              className="w-full text-left bg-dominant-200 hover:brightness-95 p-2 rounded-sm flex justify-between items-center"
              onClick={() => this.onButtonClick(value)}
              key={value + i}
            >
              <span>{value}</span>
              {selectedValue === value && (
                <CheckIcon className="stroke-on-background" />
              )}
            </button>
          ))}
        </div>
      </>
    );
  }
}
