import AppleLogin from "react-apple-login";
import Translation from "src/translations/locales/translation";

import { AppleIcon } from "src/assets/icons";
import { Component, ReactNode } from "react";
import { RoutesEnum } from "src/enums/Routes";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";

import {
  AppleProviderButtonProps,
  AppleProviderButtonState,
} from "./index.interfaces";

class AppleProviderButton extends Component<
  WithTFunction<AppleProviderButtonProps>,
  AppleProviderButtonState
> {
  state: Readonly<AppleProviderButtonState> = {
    isLoading: false,
  };

  render(): ReactNode {
    const { t, id, callbackUrl } = this.props;
    const { isLoading } = this.state;
    const stateValue =
      callbackUrl === RoutesEnum.shop.replace("/", "")
        ? `redirectURI=${encodeURIComponent(
            `https://minddrops.app/${callbackUrl}`
          )}`
        : undefined;

    return (
      <AppleLogin
        clientId="app.minddrops.signin"
        redirectURI="https://api.minddrops.app/user/auth/sign-in/apple/callback"
        state={stateValue}
        usePopup={false}
        callback={(response) => {
          if (response.error) {
            this.setState({ isLoading: false });
          } else {
            this.setState({ isLoading: true });
          }
        }}
        scope="email name"
        responseMode="form_post"
        responseType="code id_token"
        render={(renderProps) => (
          <button
            id={id}
            onClick={() => {
              this.setState({ isLoading: true });
              renderProps.onClick();
            }}
            disabled={renderProps.disabled}
            className="flex items-center justify-center gap-2 py-2 px-4 w-full bg-white rounded-full"
          >
            {isLoading ? (
              <span className="loading loading-spinner" />
            ) : (
              <>
                <AppleIcon className="fill-on-background w-5 h-5 -translate-y-[2px]" />
                <p className="font-new-atten-medium">
                  {t(Translation.common.providerButtons.apple)}
                </p>
              </>
            )}
          </button>
        )}
      />
    );
  }
}

export default withTranslation()(AppleProviderButton);
