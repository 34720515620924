import imagePlaceholderLight from "src/assets/images/image-placeholder-light.png";

class HandleImageError {
  static handle(
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    className?: string
  ) {
    const imgElement = event.currentTarget;
    imgElement.onerror = null;
    imgElement.className = `object-cover ${className}`;
    imgElement.src = imagePlaceholderLight;
  }
}

export { HandleImageError };
