import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { MarkDownContentDisplay } from "src/shared-components/mark-down-content-display";
import { Skeleton } from "src/shared-components/skeleton";
import {
  PrivacyPolicyScreenContentProps,
  PrivacyPolicyScreenContentState,
} from "./index.interfaces";

class PrivacyPolicyScreenContent extends Component<
  PrivacyPolicyScreenContentProps,
  PrivacyPolicyScreenContentState
> {
  render(): ReactNode {
    const { content } = this.props;
    return (
      <section>
        {content ? <MarkDownContentDisplay content={content} /> : <Skeleton />}
      </section>
    );
  }
}

export default withTranslation()(PrivacyPolicyScreenContent);
