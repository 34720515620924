import appStoreBadge from "../assets/store-badges/app-store-badge.png";
import googlePlayBadge from "../assets/store-badges/google-play-badge.png";

import { TFunction } from "i18next";
import {
  APP_STORE_DOWNLOAD_LINK,
  GOOGLE_PLAY_DOWNLOAD_LINK,
} from "../constants/links";

export class DownloadOptionsBuilder {
  public static build(t: TFunction) {
    const downloadOptions: {
      badgeSrc: string;
      link: string;
    }[] = [
      {
        badgeSrc: googlePlayBadge,
        link: GOOGLE_PLAY_DOWNLOAD_LINK,
      },
      {
        badgeSrc: appStoreBadge,
        link: APP_STORE_DOWNLOAD_LINK,
      },
    ];

    return downloadOptions;
  }
}
