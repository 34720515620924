import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class ArrowUpRightIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="elements">
          <path
            id="Vector 4130"
            d="M8.33333 1.66895L1 9.00228"
            strokeLinecap="round"
          />
          <path
            id="Vector 6909"
            d="M4.33203 1.00195H7.9987C8.4701 1.00195 8.7058 1.00195 8.85225 1.1484C8.9987 1.29485 8.9987 1.53055 8.9987 2.00195V5.66862"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
}
