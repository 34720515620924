import { useCartStore } from "src/store";
import { useStripe } from "@stripe/react-stripe-js";
import { ProductPrice } from "src/utils/ProductPrice";
import { motion, AnimatePresence } from "framer-motion";
import { ErrorHandler } from "src/abstracts/handleError";
import { ShopCheckoutDisplaySections } from "./sections";
import { StripesRepository } from "src/components/stripe";
import { ShopCheckoutDisplayOverview } from "./sections/overview";
import { ShopCheckoutDisplayCheckout } from "./sections/checkout";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { ShopCheckoutDisplayCartPreview } from "./sections/cart-preview";

export default function ShopCheckoutDisplay() {
  const { cart } = useCartStore();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalAmountWithoutDiscount, setTotalAmountWithoutDiscount] =
    useState<number>(0);
  const [section, setSection] = useState<ShopCheckoutDisplaySections>(
    ShopCheckoutDisplaySections.overview
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const stripe = useStripe();
  const itemsInCart = cart.length;

  // change section to overview after first rendering
  useEffect(() => {
    if (itemsInCart === 0) setSection(ShopCheckoutDisplaySections.overview);
  }, [itemsInCart]);

  // prevent scrolling when not in overview section
  useEffect(() => {
    if (section !== ShopCheckoutDisplaySections.overview) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // clean up on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [section]);

  // update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // using useCallback to avoid infinite loop and memoizing the function
  const buildTotalAmount = useCallback(() => {
    let total = 0;
    let totalWithoutDiscount = 0;
    cart.forEach((product) => {
      total += ProductPrice.getAmount(product);
      totalWithoutDiscount += ProductPrice.getAmountWithoutDiscount(product);
    });
    setTotalAmount(total);
    setTotalAmountWithoutDiscount(totalWithoutDiscount);
    return total;
  }, [cart]);

  useEffect(() => {
    buildTotalAmount();
  }, [buildTotalAmount]);

  // overview submit button function
  const overviewSubmitButton = async () => {
    setSection(ShopCheckoutDisplaySections.cartPreview);
  };

  // cart preview submit button function
  const cartPreviewSubmitButton = async () => {
    setSection(ShopCheckoutDisplaySections.checkout);
  };

  // submit checkout function
  const submitCheckout = async () => {
    const priceIds: string[] = ProductPrice.getIds(cart);
    try {
      // get session id from server
      const sessionId = await new StripesRepository().sendCheckoutSession(
        priceIds
      );
      // redirect to stripe checkout
      stripe?.redirectToCheckout({ sessionId });
    } catch (e) {
      ErrorHandler.displayError(e);
    }
  };

  const buildSection = (): ReactElement => {
    switch (section) {
      case ShopCheckoutDisplaySections.overview:
        return (
          <ShopCheckoutDisplayOverview
            itemsInCart={itemsInCart}
            totalAmount={totalAmount}
            onSubmit={overviewSubmitButton}
            section={section}
          />
        );
      case ShopCheckoutDisplaySections.cartPreview:
        return (
          <ShopCheckoutDisplayCartPreview
            cart={cart}
            itemsInCart={itemsInCart}
            totalAmount={totalAmount}
            onSubmit={cartPreviewSubmitButton}
            onClose={() => setSection(ShopCheckoutDisplaySections.overview)}
            section={section}
          />
        );
      case ShopCheckoutDisplaySections.checkout:
        return (
          <ShopCheckoutDisplayCheckout
            cart={cart}
            itemsInCart={itemsInCart}
            totalAmountWithDiscount={totalAmount}
            totalAmountWithoutDiscount={totalAmountWithoutDiscount}
            discountValue={totalAmountWithoutDiscount - totalAmount}
            onSubmit={submitCheckout}
            onClose={() => setSection(ShopCheckoutDisplaySections.overview)}
          />
        );
    }
  };

  return (
    <>
      {section !== ShopCheckoutDisplaySections.overview && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.65 }}
            exit={{ opacity: 0 }}
            onClick={() => {
              setSection(ShopCheckoutDisplaySections.overview);
            }}
            className="desktop:hidden fixed left-0 top-0 w-full h-full bg-black cursor-default"
          />
        </AnimatePresence>
      )}
      <AnimatePresence mode="wait">
        <motion.div
          key={section}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 100, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`sticky desktop:static !z-[101] desktop:!z-0 left-0 bottom-0 w-full max-h-[600px] desktop:max-h-max pb-10 pt-6 px-8 bg-white desktop:bg-none flex justify-between overflow-auto ${
            itemsInCart === 0 && "hidden"
          }`}
        >
          {windowWidth < 976 ? (
            <div className="w-full h-full overflow-auto">{buildSection()}</div>
          ) : (
            <div className="w-full flex flex-col gap-6 py-8 px-24">
              <ShopCheckoutDisplayCartPreview
                cart={cart}
                itemsInCart={itemsInCart}
                totalAmount={totalAmount}
                onSubmit={cartPreviewSubmitButton}
                onClose={() => setSection(ShopCheckoutDisplaySections.overview)}
                section={section}
              />
              <ShopCheckoutDisplayCheckout
                cart={cart}
                itemsInCart={itemsInCart}
                totalAmountWithDiscount={totalAmount}
                totalAmountWithoutDiscount={totalAmountWithoutDiscount}
                discountValue={totalAmountWithoutDiscount - totalAmount}
                onSubmit={submitCheckout}
                onClose={() => setSection(ShopCheckoutDisplaySections.overview)}
              />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
}
