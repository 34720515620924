import ImprintsController from "./index.controller";

import { Imprint } from "./index.models";

class ImprintsRepository {
  private controller = new ImprintsController();

  async getLatest(): Promise<Imprint> {
    const response = await this.controller.getLatest();
    return response;
  }

  async getByVersion(version: string): Promise<Imprint> {
    const response = await this.controller.getByVersion(version);
    return response;
  }
}

export default ImprintsRepository;
