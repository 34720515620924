import BaseRepository from "src/composable/base-repository/index.repository";
import MinddropsController from "./index.controller";

import { Minddrop } from "./index.models";
import { IMinddropsQuery, IMinddropsSort } from "./index.interfaces";

class MinddropsRepository extends BaseRepository<
  IMinddropsQuery,
  IMinddropsSort,
  Minddrop
> {
  private static controller = new MinddropsController();

  constructor() {
    super({
      controller: MinddropsRepository.controller,
    });
  }
}

export default MinddropsRepository;
