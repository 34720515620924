import { Alignment } from "../alignment";

class Soundscape {
  private _id: string;
  private _active: boolean;
  private _title: string;
  private _subscriptionOnly: boolean;
  private _shortDescription: string;
  private _description: string;
  private _thumbnail: string;
  private _thumbnailAlignment: Alignment;
  private _playerBackground: string;
  private _durationInSeconds: number;
  private _artistsIds: string[];
  private _categoriesIds: string[];
  private _fileUpdatedAt: Date;
  private _updatedAt: Date;

  constructor({
    id,
    active,
    title,
    subscriptionOnly,
    shortDescription,
    description,
    thumbnail,
    thumbnailAlignment,
    playerBackground,
    durationInSeconds,
    artistsIds,
    categoriesIds,
    fileUpdatedAt,
    updatedAt,
  }: {
    id: string;
    active: boolean;
    title: string;
    subscriptionOnly: boolean;
    shortDescription: string;
    description: string;
    thumbnail: string;
    thumbnailAlignment: Alignment;
    playerBackground: string;
    durationInSeconds: number;
    artistsIds: string[];
    categoriesIds: string[];
    fileUpdatedAt: Date;
    updatedAt: Date;
  }) {
    this._id = id;
    this._active = active;
    this._subscriptionOnly = subscriptionOnly;
    this._shortDescription = shortDescription;
    this._description = description;
    this._title = title;
    this._thumbnail = thumbnail;
    this._thumbnailAlignment = thumbnailAlignment;
    this._playerBackground = playerBackground;
    this._durationInSeconds = durationInSeconds;
    this._artistsIds = artistsIds;
    this._categoriesIds = categoriesIds;
    this._fileUpdatedAt = fileUpdatedAt;
    this._updatedAt = updatedAt;
  }

  // getters
  get id(): string {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get title(): string {
    return this._title;
  }

  get subscriptionOnly(): boolean {
    return this._subscriptionOnly;
  }

  get shortDescription(): string {
    return this._shortDescription;
  }

  get description(): string {
    return this._description;
  }

  get thumbnail(): string {
    return this._thumbnail;
  }

  get thumbnailAlignment(): Alignment {
    return this._thumbnailAlignment;
  }

  get playerBackground(): string {
    return this._playerBackground;
  }

  get durationInSeconds(): number {
    return this._durationInSeconds;
  }

  get artistsIds(): string[] {
    return this._artistsIds;
  }

  get categoriesIds(): string[] {
    return this._categoriesIds;
  }

  get fileUpdatedAt(): Date {
    return this._fileUpdatedAt;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  public static fromJson(data: any): Soundscape {
    return new Soundscape({
      id: data.id,
      active: data.active,
      title: data.title,
      subscriptionOnly: data.subscriptionOnly,
      shortDescription: data.shortDescription,
      description: data.description,
      thumbnail: data.thumbnail,
      thumbnailAlignment: new Alignment(data.thumbnailAlignment),
      playerBackground: data.playerBackground,
      durationInSeconds: data.durationInSeconds,
      artistsIds: data.artistsIds,
      categoriesIds: data.categoriesIds,
      fileUpdatedAt: new Date(data.fileUpdatedAt),
      updatedAt: new Date(data.updatedAt),
    });
  }

  public static maybeFromJson(data: any): Soundscape | null {
    try {
      return Soundscape.fromJson(data);
    } catch (e) {
      return null;
    }
  }
}

export { Soundscape };
