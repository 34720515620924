import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { FormatPriceAmount } from "src/utils/FormatPriceAmount";
import { ShopCheckoutDisplayOverviewProps } from "./index.interfaces";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { ShopCheckoutDisplaySections } from "../index.enums";

export default function ShopCheckoutDisplayOverview({
  itemsInCart,
  totalAmount,
  onSubmit,
  section,
}: ShopCheckoutDisplayOverviewProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center justify-between gap-5 desktop:hidden">
      {/* itmes in cart and total amount */}
      <div className="flex flex-col">
        <h1 className="text-sm opacity-50">
          {t(Translation.general.article, {
            count: itemsInCart,
          })}
        </h1>
        <h1 className="font-new-atten-medium text-normal">
          {`${t(Translation.general.total)}: `}
          <span className="underline font-new-atten-medium text-normal">
            {FormatPriceAmount.withCurrency(totalAmount)}
          </span>
        </h1>
      </div>
      <SimpleElevatedButton
        id="shop-checkout-button"
        text={
          section === ShopCheckoutDisplaySections.overview
            ? t(Translation.general.continue)
            : t(Translation.general.continueToCheckout)
        }
        onClick={onSubmit}
        disabled={itemsInCart === 0}
        className="!px-4 !h-12 !min-w-[10rem] !w-[50%] tablet:!w-1/2"
      />
    </div>
  );
}
