import Endpoints from "src/constants/endpoints";
import AxiosClient from "src/networking/axios-client/index.client";

import { Stripe } from "./index.models";

class StripesController {
  public static async get(): Promise<Stripe[]> {
    const res = await AxiosClient.get({
      url: Endpoints.stripe.products,
      parser: (res) => res.data.products,
    });
    return res;
  }

  public static async sendCheckoutSession(priceIds: string[]): Promise<string> {
    const res = await AxiosClient.post({
      url: Endpoints.stripe.checkoutSession,
      body: {
        priceIds,
      },
      parser: (res) => res.data.sessionId,
    });
    return res;
  }
}

export default StripesController;
