import { Skeleton } from "src/shared-components/skeleton";
import { MarkDownContentDisplay } from "src/shared-components/mark-down-content-display";

import { ShopRevocationRightScreenContentProps } from "./index.interfaces";

export default function ShopRevocationRightScreenContent({
  content,
}: ShopRevocationRightScreenContentProps) {
  return (
    <section>
      {content ? <MarkDownContentDisplay content={content} /> : <Skeleton />}
    </section>
  );
}
