import Endpoints from "src/constants/endpoints";
import BaseController from "src/composable/base-controller";

import { IExercisesQuery, IExercisesSort } from "./index.interfaces";
import { Exercise } from "./index.models";

class ExercisesController extends BaseController<
  IExercisesQuery,
  IExercisesSort,
  Exercise
> {
  constructor() {
    super({
      endpoints: {
        overview: Endpoints.exercises,
      },
      buildSummary: (json: any) => Exercise.fromJson(json),
    });
  }
}

export default ExercisesController;
