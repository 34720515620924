import imagePlaceholderLight from "src/assets/images/image-placeholder-light.png";

import { Component, ReactNode } from "react";
import { BackgroundImageProps, BackgroundImageState } from "./index.interfaces";

class BackgroundImage extends Component<
  BackgroundImageProps,
  BackgroundImageState
> {
  render(): ReactNode {
    const { src, className, title, children } = this.props;

    return src.isEmpty() ? (
      // show place holder image if the source was empty
      <div
        title={title}
        className={className}
        style={{
          backgroundImage: `url(${imagePlaceholderLight})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {children}
      </div>
    ) : (
      <div
        title={title}
        className={className}
        style={{
          backgroundImage: `url(${src})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {children}
      </div>
    );
  }
}

export default BackgroundImage;
