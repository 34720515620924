import "./index.css";
import MarkdownPreview from "@uiw/react-markdown-preview";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";

import {
  MarkDownContentDisplayProps,
  MarkDownContentDisplayState,
} from "./index.interfaces";

class MarkDownContentDisplay extends Component<
  WithTFunction<MarkDownContentDisplayProps>,
  MarkDownContentDisplayState
> {
  render(): ReactNode {
    const { content, className } = this.props;

    return (
      <MarkdownPreview
        source={content ?? ""}
        className={`!bg-transparent !text-on-background !overflow-auto !leading-7 !border-none !border-e-0 !outline-none !no-underline link-color ${className}`}
      />
    );
  }
}

export default withTranslation()(MarkDownContentDisplay);
