import Translation from "src/translations/locales/translation";

import { Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { MenuCloseIcon } from "src/assets/icons";
import { ShopCheckoutDisplayOverview } from "../overview";
import { ShopCheckoutDisplayCartPreviewProps } from "./index.interfaces";
import { ShopCheckoutDisplayCartPreviewCard } from "./components/cart-preview-card";

export default function ShopCheckoutDisplayCartPreview({
  cart,
  itemsInCart,
  totalAmount,
  onSubmit,
  onClose,
  section,
}: ShopCheckoutDisplayCartPreviewProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col gap-16 desktop:gap-0">
      {/* cart items card */}
      <div className="flex flex-col gap-4 desktop:gap-0">
        {/* header */}
        <div className="w-full flex justify-between items-center">
          {/* title */}
          <h1 className="text-normal">{t(Translation.general.yourCart)}</h1>
          {/* close icon */}
          <button className="desktop:hidden p-1" onClick={onClose}>
            <MenuCloseIcon className="w-4 h-4 stroke-on-background" />
          </button>
        </div>
        <Divider className="hidden desktop:flex !mb-4 !mt-4" />
        <div className="flex flex-col gap-3">
          {cart.map((product) => (
            <ShopCheckoutDisplayCartPreviewCard
              key={product.id}
              product={product}
            />
          ))}
        </div>
      </div>
      <ShopCheckoutDisplayOverview
        itemsInCart={itemsInCart}
        totalAmount={totalAmount}
        onSubmit={onSubmit}
        section={section}
      />
    </div>
  );
}
