import Translation from "src/translations/locales/translation";

import { WithTFunction } from "src/types/WithTFunction";
import { Component, ReactNode } from "react";
import { SafeLinkProps, SafeLinkState } from "./index.interfaces";
import { withTranslation } from "react-i18next";
import { ModalDialogComponent } from "../modal-dialog";
import { ErrorHandler } from "src/abstracts/handleError";
import { Link } from "react-router-dom";
import { ButtonOptionStyles } from "../modal-dialog/index.interfaces";

class SafeLink extends Component<WithTFunction<SafeLinkProps>, SafeLinkState> {
  render(): ReactNode {
    const { to, t, children, className, style, linkClassName } = this.props;

    const checkIdLinkIsExternal = (): boolean => {
      if (to.isEmpty() || to.startsWith("/")) {
        return false;
      }
      try {
        const url = new URL(to);
        return url.host !== "minddrops.app";
      } catch (e) {
        ErrorHandler.displayError(e);
        return false;
      }
    };

    return (
      <span
        className={`relative cursor-pointer w-fit ${className}`}
        style={style}
      >
        {checkIdLinkIsExternal() ? (
          <>
            {children}
            <span className="absolute w-full h-full top-0 left-0">
              <ModalDialogComponent
                title={t(Translation.general.safeLinkWarning.title)}
                dialogText={""}
                acceptButton={{
                  label: t(Translation.general.ok),
                  onClick: () => {
                    window.open(to);
                  },
                  style: ButtonOptionStyles.primary,
                }}
                cancelButton={{
                  label: t(Translation.general.cancel),
                  onClick: () => {},
                  style: ButtonOptionStyles.primary,
                }}
                showLogo={true}
              />
            </span>
          </>
        ) : (
          <Link to={to} className={linkClassName}>
            {children}
          </Link>
        )}
      </span>
    );
  }
}

export default withTranslation()(SafeLink);
