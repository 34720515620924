import { Component, ReactNode } from "react";
import { ImageProps, ImageState } from "./index.interfaces";
import { HandleImageError } from "src/utils/ImageErrorHandler";

class Image extends Component<ImageProps, ImageState> {
  render(): ReactNode {
    const { alt, src, className, title } = this.props;

    return (
      <img
        src={src}
        alt={alt}
        title={title}
        className={className}
        onError={(e) => HandleImageError.handle(e, className)}
      />
    );
  }
}

export default Image;
