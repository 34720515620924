import { Alignment } from "../alignment";

class CouponCollectionResponse {
  private _title: string;
  private _thumbnail: string;

  constructor({ title, thumbnail }: { title: string; thumbnail: string }) {
    this._title = title;
    this._thumbnail = thumbnail;
  }

  get title() {
    return this._title;
  }

  get thumbnail() {
    return this._thumbnail;
  }

  public static fromJson(data: any): CouponCollectionResponse {
    if (typeof data.title !== "string" || typeof data.thumbnail !== "string")
      throw new Error("Invalid json for CouponCollectionResponse");

    return new CouponCollectionResponse({
      title: data.title,
      thumbnail: data.thumbnail,
    });
  }
}

class Collection {
  private _id: string;
  private _active: boolean;
  private _title: string;
  private _subscriptionOnly: boolean;
  private _thumbnail: string;
  private _thumbnailAlignment: Alignment;
  private _description: string;
  private _shortDescription: string;
  private _categoriesIds: string[];
  private _exercisesIds: string[];
  private _updatedAt: Date;

  constructor({
    id,
    active,
    title,
    subscriptionOnly,
    thumbnail,
    thumbnailAlignment,
    description,
    shortDescription,
    categoriesIds,
    exercisesIds,
    updatedAt,
  }: {
    id: string;
    active: boolean;
    title: string;
    subscriptionOnly: boolean;
    thumbnail: string;
    thumbnailAlignment: Alignment;
    description: string;
    shortDescription: string;
    categoriesIds: string[];
    exercisesIds: string[];
    updatedAt: Date;
  }) {
    this._id = id;
    this._active = active;
    this._subscriptionOnly = subscriptionOnly;
    this._title = title;
    this._thumbnail = thumbnail;
    this._thumbnailAlignment = thumbnailAlignment;
    this._description = description;
    this._shortDescription = shortDescription;
    this._categoriesIds = categoriesIds;
    this._exercisesIds = exercisesIds;
    this._updatedAt = updatedAt;
  }

  // getters
  get id(): string {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get title(): string {
    return this._title;
  }

  get subscriptionOnly(): boolean {
    return this._subscriptionOnly;
  }

  get thumbnail(): string {
    return this._thumbnail;
  }

  get thumbnailAlignment(): Alignment {
    return this._thumbnailAlignment;
  }

  get description(): string {
    return this._description;
  }

  get shortDescription(): string {
    return this._shortDescription;
  }

  get categoriesIds(): string[] {
    return this._categoriesIds;
  }

  get exercisesIds(): string[] {
    return this._exercisesIds;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  public static fromJson(data: any): Collection {
    return new Collection({
      id: data.id,
      active: data.active,
      title: data.title,
      subscriptionOnly: data.subscriptionOnly,
      thumbnail: data.thumbnail,
      thumbnailAlignment: new Alignment(data.thumbnailAlignment),
      description: data.description,
      shortDescription: data.shortDescription,
      categoriesIds: data.categoriesIds,
      exercisesIds: data.exercisesIds,
      updatedAt: data.updatedAt,
    });
  }

  public static maybeFromJson(data: any): Collection | null {
    try {
      return Collection.fromJson(data);
    } catch (e) {
      return null;
    }
  }
}

export { Collection, CouponCollectionResponse };
