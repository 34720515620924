import { useCartStore } from "src/store";
import { MenuCloseIcon } from "src/assets/icons";
import { Image } from "src/shared-components/image";
import { ProductPrice } from "src/utils/ProductPrice";
import { FormatPriceAmount } from "src/utils/FormatPriceAmount";
import { ShopCheckoutDisplayCartPreviewCardProps } from "./index.interfaces";

export default function ShopCheckoutDisplayCartPreviewCard({
  product,
}: ShopCheckoutDisplayCartPreviewCardProps) {
  const { removeProduct } = useCartStore();

  const removeFromCart = () => {
    removeProduct(product);
  };

  return (
    <div className="w-full flex justify-between items-center gap-3 p-2 pr-4 rounded-lg bg-background">
      {/* name & image */}
      <div className="flex gap-2 items-center">
        <Image
          src={product.images[0]}
          alt={`shop-checkout-display-cart-preview-card-${product.id}`}
          title={product.name}
          className="w-12 h-12 rounded-lg object-cover"
        />
        <h1 className="max-h-12 overflow-clip text-ellipsis">
          {product.name.toUpperCase()}
        </h1>
      </div>
      {/* remove icon & price */}
      <div className="flex items-center gap-4">
        <h1>
          {FormatPriceAmount.withCurrency(ProductPrice.getAmount(product))}
        </h1>
        <button onClick={removeFromCart}>
          <MenuCloseIcon className="stroke-on-background min-w-6 min-h-6" />
        </button>
      </div>
    </div>
  );
}
