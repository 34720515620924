import SimpleInput from "../index.component";

import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { CloseEyeIcon, EyeIcon } from "src/assets/icons";
import { FormFieldErrorMessage } from "src/utils/FormFieldErrorMessage";
import {
  SimplePasswordInputProps,
  SimplePasswordInputState,
} from "./index.interfaces";

class SimplePasswordInput extends SimpleInput<
  string,
  WithTFunction<SimplePasswordInputProps<string>>,
  SimplePasswordInputState
> {
  state: Readonly<SimplePasswordInputState> = {
    isPasswordHidden: true,
    errorMessage: null,
  };

  onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  onInputTypeChange = () => {
    const { isPasswordHidden } = this.state;
    isPasswordHidden
      ? this.setState({ isPasswordHidden: false })
      : this.setState({ isPasswordHidden: true });
  };

  render() {
    const { id, name, placeholder, value } = this.props;
    const { isPasswordHidden, errorMessage } = this.state;

    return (
      <div className="relative">
        <input
          id={id}
          className="w-full h-10 flex justify-center items-center px-3 text-sm rounded-lg bg-white selection:bg-on-background selection:text-background"
          name={name}
          type={isPasswordHidden ? "password" : "text"}
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.onInputChange(e)}
          autoFocus={false}
        />

        {/* error message */}
        <FormFieldErrorMessage errorMessage={errorMessage} />
        <span
          className="absolute right-4 top-[10px] cursor-pointer"
          onClick={this.onInputTypeChange}
        >
          {isPasswordHidden ? (
            <EyeIcon className="stroke-on-background w-5 h-5" />
          ) : (
            <CloseEyeIcon className="stroke-on-background w-5 h-5" />
          )}
        </span>
      </div>
    );
  }
}

export default withTranslation()(SimplePasswordInput);
