import Translation from "src/translations/locales/translation";

import { useCartStore } from "src/store";
import { Stripe } from "src/components/stripe";
import { useTranslation } from "react-i18next";
import { ShopCardProps } from "./index.interfaces";
import { CloseCircleIcon } from "src/assets/icons";
import { Image } from "src/shared-components/image";
import { useCallback, useEffect, useState } from "react";
import { ShopCardProductPrice } from "./components/product-price";
import { SimpleElevatedButton } from "../form/fields/buttons/simple-elevated-button";
import { SimpleOutlineButton } from "../form/fields/buttons/simple-outline-button";

export function ShopCard({ product }: ShopCardProps) {
  const { addProduct, removeProduct, cart } = useCartStore();
  const { t } = useTranslation();
  const [isAddedToShop, setIsAddedToShop] = useState<boolean>(
    cart.some((p) => p.id === product.id)
  );

  // update button state based on isAddedToShop boolean
  // using useCallback to avoid infinite loop and memoizing the function
  const updateIsAddedToShop = useCallback(() => {
    setIsAddedToShop(cart.some((p) => p.id === product.id));
  }, [cart, product.id]);

  useEffect(() => {
    updateIsAddedToShop();
  }, [updateIsAddedToShop]);

  // add to cart function
  const addToCart = (product: Stripe) => {
    addProduct(product);
    setIsAddedToShop(true);
  };

  // remove from cart function
  const removeFromCart = (product: Stripe) => {
    removeProduct(product);
    setIsAddedToShop(false);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      {/* image */}
      <Image
        src={product.images[0]}
        alt={product.name}
        className="w-full h-56 object-cover rounded-lg"
      />
      <div className="w-full flex flex-col gap-4 desktop:max-w-[250px] max-w-full">
        {/* name & description */}
        <div className="flex flex-col">
          <h1 className="h-6 text-normal font-new-atten-medium text-ellipsis overflow-clip whitespace-nowrap">
            {product.name.toUpperCase()}
          </h1>
          <h1 className="h-6 text-sm opacity-50 text-ellipsis overflow-clip whitespace-nowrap">
            {product.description.toUpperCase()}
          </h1>
        </div>
        {/* price */}
        <ShopCardProductPrice product={product} />
        {/* button */}
        {isAddedToShop ? (
          <SimpleOutlineButton
            id="shop-remove-from-cart-button"
            onClick={() => removeFromCart(product)}
            text={t(Translation.screens.shop.shopCard.button.alreadyAdded)}
            icon={<CloseCircleIcon className="w-6 h-6 stroke-primary" />}
          />
        ) : (
          <SimpleElevatedButton
            id="shop-add-to-cart-button"
            onClick={() => addToCart(product)}
            text={t(Translation.screens.shop.shopCard.button.addToCart)}
          />
        )}
      </div>{" "}
    </div>
  );
}
