class Alignment {
  x: number;
  y: number;

  constructor({ x, y }: { x: number; y: number }) {
    this.x = x;
    this.y = y;
  }

  public static get center(): Alignment {
    return new Alignment({ x: 0, y: 0 });
  }

  public static get centerRight(): Alignment {
    return new Alignment({ x: 1, y: 0 });
  }

  public static get centerLeft(): Alignment {
    return new Alignment({ x: -1, y: 0 });
  }

  public static get topCenter(): Alignment {
    return new Alignment({ x: 0, y: -1 });
  }

  public static get topRight(): Alignment {
    return new Alignment({ x: 1, y: -1 });
  }

  public static get topLeft(): Alignment {
    return new Alignment({ x: -1, y: -1 });
  }

  public static get bottomCenter(): Alignment {
    return new Alignment({ x: 0, y: 1 });
  }

  public static get bottomRight(): Alignment {
    return new Alignment({ x: 1, y: 1 });
  }

  public static get bottomLeft(): Alignment {
    return new Alignment({ x: -1, y: 1 });
  }

  public static fromJson(data: any): Alignment {
    return new Alignment({ x: data.x, y: data.y });
  }

  public get tailwindPosition(): string {
    const xMap: { [key: number]: string } = {
      [-1]: "left",
      0: "center",
      1: "right",
    };

    const yMap: { [key: number]: string } = {
      [-1]: "top",
      0: "center",
      1: "bottom",
    };

    const xPos = xMap[this.x];
    const yPos = yMap[this.y];

    if (xPos === "center" && yPos === "center") {
      return "center";
    }

    return `${xPos}-${yPos}`;
  }
}

export default Alignment;
