import Endpoints from "src/constants/endpoints";
import BaseController from "src/composable/base-controller";

import { ISoundscapesQuery, ISoundscapesSort } from "./index.interfaces";
import { Soundscape } from "./index.models";

class SoundscapesController extends BaseController<
  ISoundscapesQuery,
  ISoundscapesSort,
  Soundscape
> {
  constructor() {
    super({
      endpoints: {
        overview: Endpoints.soundscapes,
      },
      buildSummary: (json: any) => Soundscape.fromJson(json),
    });
  }
}

export default SoundscapesController;
