import { Alignment } from "../alignment";

class Exercise {
  private _id: string;
  private _active: boolean;
  private _title: string;
  private _subscriptionOnly: boolean;
  private _shortDescription: string;
  private _description: string;
  private _thumbnail: string;
  private _thumbnailAlignment: Alignment;
  private _playerBackground: string;
  private _versionsIds: string[];
  private _artistsIds: string[];
  private _categoriesIds: string[];
  private _deprecated: boolean;
  private _updatedAt: Date;

  constructor({
    id,
    active,
    title,
    subscriptionOnly,
    shortDescription,
    description,
    thumbnail,
    thumbnailAlignment,
    playerBackground,
    versionsIds,
    artistsIds,
    categoriesIds,
    deprecated,
    updatedAt,
  }: {
    id: string;
    active: boolean;
    title: string;
    subscriptionOnly: boolean;
    shortDescription: string;
    description: string;
    thumbnail: string;
    thumbnailAlignment: Alignment;
    playerBackground: string;
    versionsIds: string[];
    artistsIds: string[];
    categoriesIds: string[];
    deprecated: boolean;
    updatedAt: Date;
  }) {
    this._id = id;
    this._active = active;
    this._title = title;
    this._subscriptionOnly = subscriptionOnly;
    this._shortDescription = shortDescription;
    this._description = description;
    this._thumbnail = thumbnail;
    this._thumbnailAlignment = thumbnailAlignment;
    this._playerBackground = playerBackground;
    this._versionsIds = versionsIds;
    this._artistsIds = artistsIds;
    this._categoriesIds = categoriesIds;
    this._deprecated = deprecated;
    this._updatedAt = updatedAt;
  }

  // getters
  get id(): string {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get title(): string {
    return this._title;
  }

  get subscriptionOnly(): boolean {
    return this._subscriptionOnly;
  }

  get shortDescription(): string {
    return this._shortDescription;
  }

  get description(): string {
    return this._description;
  }

  get thumbnail(): string {
    return this._thumbnail;
  }

  get thumbnailAlignment(): Alignment {
    return this._thumbnailAlignment;
  }

  get playerBackground(): string {
    return this._playerBackground;
  }

  get versionsIds(): string[] {
    return this._versionsIds;
  }

  get artistsIds(): string[] {
    return this._artistsIds;
  }

  get categoriesIds(): string[] {
    return this._categoriesIds;
  }

  get deprecated(): boolean {
    return this._deprecated;
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  public static fromJson(data: any): Exercise {
    return new Exercise({
      id: data.id,
      active: data.active,
      title: data.title,
      subscriptionOnly: data.subscriptionOnly,
      shortDescription: data.shortDescription,
      description: data.description,
      thumbnail: data.thumbnail,
      thumbnailAlignment: new Alignment(data.thumbnailAlignment),
      playerBackground: data.playerBackground,
      versionsIds: data.versionsIds,
      artistsIds: data.artistsIds,
      categoriesIds: data.categoriesIds,
      deprecated: data.deprecated,
      updatedAt: new Date(data.updatedAt),
    });
  }

  public static maybeFromJson(data: any): Exercise | null {
    try {
      return Exercise.fromJson(data);
    } catch (e) {
      return null;
    }
  }
}

export { Exercise };
