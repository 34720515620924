import Translation from "src/translations/locales/translation";
import logo94x94 from "src/assets/images/logo94x94.png";

import { FAQ, FAQsRepository } from "src/components/faq";
import { Component, ReactNode } from "react";
import { ErrorHandler } from "src/abstracts/handleError";
import { HeaderComponent } from "src/shared-components/header";
import { WithTFunction } from "src/types/WithTFunction";
import { withTranslation } from "react-i18next";
import { MessageQuestionIcon } from "src/assets/icons";
import { FAQScreenFAQContainer } from "./component/faq-container";
import { Image } from "src/shared-components/image";
import { DownloadOptionsBuilder } from "src/utils/DownloadOptionsBuilder";
import { SafeLink } from "src/shared-components/safe-link";
import { FooterComponent } from "src/shared-components/footer";

interface FAQScreenState {
  faqs: FAQ[] | null;
}

class FAQScreen extends Component<WithTFunction<{}>, FAQScreenState> {
  state: Readonly<FAQScreenState> = {
    faqs: null,
  };

  async componentDidMount(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      const faqs = await new FAQsRepository().getAll();
      this.setState({ faqs });
    } catch (e) {
      ErrorHandler.displayError(e);
    }
  }

  render(): ReactNode {
    const { t } = this.props;
    const { faqs } = this.state;

    return (
      <main>
        <HeaderComponent />
        <section className="w-full flex flex-col gap-8 justify-center items-center p-10 tablet:px-16 desktop:px-20">
          {/* icon and title */}
          <div className="flex flex-col items-center gap-2">
            {/* icon */}
            <div className="w-12 h-12 rounded-md bg-primary-light flex justify-center items-center">
              <MessageQuestionIcon className="stroke-primary" />
            </div>
            {/* title */}
            <h1 className="text-lg font-new-atten-bold">
              {t(Translation.screens.faqs.title)}
            </h1>
          </div>
          {/* faqs */}
          <FAQScreenFAQContainer faqs={faqs} />
          {/* download options */}
          <div className="flex min-w-full flex-wrap gap-5 items-center justify-center tablet:justify-between bg-dominant-200 p-6 rounded-xl">
            <div className="flex items-center gap-2">
              <Image src={logo94x94} className="w-8 h-8" alt="minddrops-logo" />
              <h1>
                {t(
                  Translation.screens.privacyPolicy.footer.downloadOptionsLabel
                )}
              </h1>
            </div>
            <div className="flex flex-wrap mobile:w-full items-center justify-center tablet:justify-between gap-4">
              {DownloadOptionsBuilder.build(t).map((option, i) => (
                <SafeLink
                  to={option.link}
                  key={`app-insight-download-options-${i}`}
                  className="flex gap-2 hover:brightness-90 items-center w-40 justify-center bg-on-background rounded-lg"
                >
                  <Image
                    className="w-40 h-11 object-cover rounded-lg"
                    src={option.badgeSrc}
                    alt={option.link}
                  />
                </SafeLink>
              ))}
            </div>
          </div>
        </section>
        {/* footer */}
        <FooterComponent />
      </main>
    );
  }
}

export default withTranslation()(FAQScreen);
