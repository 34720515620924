import Endpoints from "src/constants/endpoints";
import BaseController from "src/composable/base-controller";

import { ICollectionsQuery, ICollectionsSort } from "./index.interfaces";
import { Collection } from "./index.models";

class CollectionsController extends BaseController<
  ICollectionsQuery,
  ICollectionsSort,
  Collection
> {
  constructor() {
    super({
      endpoints: {
        overview: Endpoints.collections,
      },
      buildSummary: (json: any) => Collection.fromJson(json),
    });
  }
}

export default CollectionsController;
