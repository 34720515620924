import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { redirectToAppOrStore } from "src/utils/redirectToAppOrStore";
import { EntitlementDuration } from "src/components/entitlement/index.enums";
import { BackgroundImage } from "src/shared-components/background-image";
import {
  RedeemCodeScreenSuccessSubmitProps,
  RedeemCodeScreenSuccessSubmitState,
} from "./index.interfaces";

class RedeemCodeScreenSuccessSubmit extends Component<
  WithTFunction<RedeemCodeScreenSuccessSubmitProps>,
  RedeemCodeScreenSuccessSubmitState
> {
  render(): ReactNode {
    const { t, couponRes } = this.props;

    const getDateStringFromEntitlementDuration = (
      duration: EntitlementDuration
    ): string => {
      const currentDate = new Date(); // current date

      switch (duration) {
        case EntitlementDuration.lifetime:
          return t(Translation.general.lifetime);

        case EntitlementDuration.monthly:
          // calculate next month's date
          const nextMonth = new Date(currentDate);
          nextMonth.setMonth(currentDate.getMonth() + 1);
          return `${t(Translation.general.until)} ${currentDate.getDate()}.${
            nextMonth.getMonth() + 1
          }.${nextMonth.getFullYear()}`;

        case EntitlementDuration.weekly:
          // calculate next week's date
          const nextWeek = new Date(currentDate);
          nextWeek.setDate(currentDate.getDate() + 7);
          return `${t(Translation.general.until)} ${nextWeek.getDate()}.${
            nextWeek.getMonth() + 1
          }.${nextWeek.getFullYear()}`;

        case EntitlementDuration.yearly:
          // calculate next year's date
          const nextYear = new Date(currentDate);
          nextYear.setFullYear(currentDate.getFullYear() + 1);
          return `${t(Translation.general.until)} ${currentDate.getDate()}.${
            currentDate.getMonth() + 1
          }.${nextYear.getFullYear()}`;

        default:
          return ""; // return empty string for unsupported duration
      }
    };

    return (
      <>
        <div className="flex flex-col w-full gap-8">
          {/* title and entitlement text */}
          <h1 className="text-lg font-new-atten-medium">
            {t(Translation.screens.redeemCode.success.title).toUpperCase()}
          </h1>
          <div className="flex flex-col gap-3">
            {couponRes.grantedEntitlement && (
              <p className="opacity-50 text-sm">
                {t(
                  Translation.screens.redeemCode.success.entitlementTextBefore
                )}
                <span className="text-primary opacity-100 brightness-90 font-new-atten-bold">
                  {getDateStringFromEntitlementDuration(
                    couponRes.grantedEntitlement.duration
                  )}
                </span>
                {t(Translation.screens.redeemCode.success.entitlementTextAfter)}
              </p>
            )}
            {/* line, only available if there are both collections and entitlement */}
            {couponRes.grantedCollections.isNotEmpty() &&
              couponRes.grantedEntitlement && (
                <div className="w-[1px] h-6 bg-dominant-300" />
              )}
            {/* collection's information */}
            {couponRes.grantedCollections.isNotEmpty() && (
              <div className="flex flex-col gap-3 relative">
                {/* collection's title */}
                <div className="flex flex-col gap-4">
                  <p className="opacity-50 text-sm">
                    {t(
                      Translation.screens.redeemCode.success
                        .collectionTextBefore
                    )}
                    <span className="text-primary opacity-100 brightness-90 font-new-atten-bold">
                      {couponRes.grantedCollections.length === 1
                        ? `${t(
                            Translation.general.collection
                          )} ${couponRes.grantedCollections.map(
                            (collection) => collection.title
                          )}`
                        : `${t(Translation.general.following)} ${t(
                            Translation.general.collections
                          )}`}
                    </span>
                    {t(
                      Translation.screens.redeemCode.success.collectionTextAfter
                    )}
                  </p>
                </div>

                {/* colleciton's thumbnails */}
                <div className="flex items-center gap-3 w-full overflow-auto rounded-md scrollbar-none">
                  {couponRes.grantedCollections.map((collection, i) => (
                    <BackgroundImage
                      key={collection.title + i}
                      title={collection.title}
                      src={collection.thumbnail}
                      className="min-w-28 h-16 rounded-lg relative overflow-clip"
                    >
                      <span className="absolute left-0 top-0 w-full h-full bg-black opacity-30" />
                      <h1 className="absolute left-2 pr-2 bottom-2 text-white drop-shadow-xl text-2xs leading-3 w-full max-w-full">
                        {collection.title.slice(0, 60).toUpperCase()}
                      </h1>
                    </BackgroundImage>
                  ))}
                </div>
                {/* gradinet on the right of box */}
                <div
                  className="w-11 h-16 absolute right-0 bottom-0"
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(255,255,255,0) 30%, rgba(242,240,238,1) 100%)",
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* redirect home button */}
        <SimpleElevatedButton
          id="redeem-code-redirect-home-button"
          onClick={() => redirectToAppOrStore(true)}
          text={t(Translation.screens.redeemCode.success.redirectHomeButton)}
        />
      </>
    );
  }
}

export default withTranslation()(RedeemCodeScreenSuccessSubmit);
