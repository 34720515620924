import { StepListItem } from "../../..";
import { Image } from "src/shared-components/image";

export default function StepListItemForDesktop({
  steps,
}: {
  steps: StepListItem[];
}) {
  return (
    <div className="hidden desktop:flex flex-col gap-20 w-full">
      {/* images list */}
      <ol className="w-full flex justify-center flex-wrap items-center gap-16 list-decimal">
        {/* Row 1 */}
        {steps.slice(0, 3).map((step) => (
          <ImageListItem key={step.image.alt} step={step} />
        ))}
        {/* Row 2 */}
        {steps.slice(3, 5).map((step) => (
          <ImageListItem key={step.image.alt} step={step} />
        ))}
      </ol>
      {/* descriptions list */}
      <ol className="w-full flex flex-col gap-10 text-left">
        {steps.map((step, i) => (
          <li key={step.image.alt} className="text-lg">
            {i + 1}) {step.description}
          </li>
        ))}
      </ol>
    </div>
  );
}

function ImageListItem({ step }: { step: StepListItem }) {
  return (
    <li key={step.image.alt} className="pl-3">
      <Image
        src={step.image.src}
        alt={step.image.alt}
        className="w-64 border-dominant-300 border-solid border-1 rounded-lg self-center"
      />
    </li>
  );
}
