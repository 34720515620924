import Translation from "../translations/locales/translation";

import { TFunction } from "i18next";
import { ErrorHandler } from "../abstracts/handleError";

export class FirebaseAuthErrorHandler {
  public static handle(e: any, t: TFunction): void {
    switch (e.code) {
      case "auth/invalid-credential":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.firebase.invalidCredential))
        );
        break;
      case "auth/invalid-email":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.firebase.invalidEmail))
        );
        break;
      case "auth/user-not-found":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.firebase.userNotFound))
        );
        break;
      case "auth/network-request-failed":
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.firebase.networkRequestFailed))
        );
        break;
      default:
        ErrorHandler.displayError(
          new Error(t(Translation.common.errors.firebase.unknown))
        );
        break;
    }
  }

  public static getMessage(e: any, t: TFunction): string {
    switch (e.code) {
      case "auth/invalid-credential":
        return t(Translation.common.errors.firebase.invalidCredential);
      case "auth/invalid-email":
        return t(Translation.common.errors.firebase.invalidEmail);
      case "auth/user-not-found":
        return t(Translation.common.errors.firebase.userNotFound);
      case "auth/network-request-failed":
        return t(Translation.common.errors.firebase.networkRequestFailed);
      default:
        return t(Translation.common.errors.firebase.unknown);
    }
  }
}
