import FormField from "../index.component";

import { ReactElement, RefObject } from "react";
import { CheckboxProps } from "./index.interfaces";
import { FormFieldState } from "../index.interfaces";

export default abstract class SimpleCheckbox<
  P extends CheckboxProps,
  S extends FormFieldState
> extends FormField<P, S> {
  componentDidUpdate(prevProps: Readonly<P>): void {
    if (prevProps.value !== this.props.value) {
      this.validate();
    }
    if (this.props.customErrorMessage) {
      if (prevProps.customErrorMessage !== this.props.customErrorMessage) {
        this.setState({ errorMessage: this.props.customErrorMessage });
      }
    }
  }

  validate(): RefObject<HTMLDivElement> | null {
    const { validator, value } = this.props;
    if (validator) {
      const errorMessage = validator(value);
      this.setState({ errorMessage });
      return errorMessage === null ? null : this.ref;
    }
    return null;
  }

  clearInput(): void {
    this.props.onChange(false);
    this.setState({ errorMessage: null });
  }

  abstract render(): ReactElement<P> | null;
}
