import AxiosClient from "src/networking/axios-client/index.client";
import Pagination from "src/interfaces/pagination";

import { BaseControllerEndpoints } from "./index.interfaces";
import { BaseControllerOverviewResposne } from "./index.models";

/**
 * @description The base controller for all controllers.
 *
 * @param {BaseControllerEndpoints} endpoints - The endpoints for the controller.
 *
 * @template Query - The type of the query model (e.g. AppQuery).
 * @template Sort - The type of the sort model (e.g. AppSort).
 * @template S - The type of the summary model (e.g. SummaryApp).
 */
class BaseController<Query, Sort, S> {
  private readonly endpoints: BaseControllerEndpoints;
  private readonly buildSummary: (json: any) => S;

  constructor({
    endpoints,
    buildSummary,
  }: {
    endpoints: BaseControllerEndpoints;
    buildSummary: (json: any) => S;
  }) {
    this.endpoints = endpoints;
    this.buildSummary = buildSummary;
  }

  async get({
    query,
    sort,
    pagination,
  }: {
    query?: Query;
    sort?: Sort;
    pagination?: Pagination;
  } = {}): Promise<S[]> {
    const body: any = {};

    if (query) {
      body.query = query;
    }

    if (sort) {
      body.sort = sort;
    }

    if (pagination) {
      body.pagination = {
        page: pagination.page,
        pageSize: pagination.pageSize,
      };
    }

    const res = await AxiosClient.post({
      url: this.endpoints.overview,
      body: body,
      parser: (res) =>
        BaseControllerOverviewResposne.fromAxiosResponse<S>(
          res,
          this.buildSummary
        ),
    });
    return res.data;
  }
}

export default BaseController;
