import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class AppleIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="Frame 1000002572">
          <path
            id="Vector"
            d="M18.775 12.6851C18.7635 10.7179 19.6548 9.23525 21.4547 8.14202C20.448 6.69977 18.925 5.90653 16.9174 5.75365C15.0165 5.60366 12.9368 6.8613 12.1753 6.8613C11.3705 6.8613 9.53017 5.80557 8.08215 5.80557C5.09381 5.85173 1.91797 8.18817 1.91797 12.9418C1.91797 14.3466 2.17469 15.7975 2.68813 17.2917C3.37464 19.2589 5.84955 24.0789 8.43118 24.001C9.78113 23.9693 10.7359 23.0434 12.4926 23.0434C14.1973 23.0434 15.08 24.001 16.5857 24.001C19.1904 23.9635 21.4288 19.582 22.0806 17.609C18.5875 15.9619 18.775 12.7861 18.775 12.6851ZM15.7434 3.88738C17.2058 2.15091 17.0732 0.5702 17.0299 0.00195312C15.7376 0.0769502 14.2435 0.881727 13.3925 1.87111C12.4551 2.93261 11.9041 4.24506 12.0224 5.72481C13.4185 5.83153 14.6934 5.11329 15.7434 3.88738Z"
          />
        </g>
      </svg>
    );
  }
}
