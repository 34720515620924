const baseUrl = process.env.REACT_APP_BASE_URL;

class Endpoints {
  static user = {
    auth: {
      signIn: {
        credentials: `${baseUrl}/user/auth/sign-in/credentials`,
        google: `${baseUrl}/user/auth/sign-in/google`,
        apple: `${baseUrl}/user/auth/sign-in/apple`,
      },
      email: {
        requestVerification: `${baseUrl}/user/auth/email/request-verification`,
      },
      forgotPassword: {
        emailAutoReset: `${baseUrl}/user/auth/forgot-password/email-auto-reset`,
      },
    },
  };
  static apps = `${baseUrl}/apps`;
  static faqs = `${baseUrl}/faqs?page=1&pageSize=20`;
  static privacyPolicies = `${baseUrl}/privacy-policy`;
  static termsOfServices = `${baseUrl}/terms-of-service`;
  static imprints = `${baseUrl}/imprint`;
  static testimonials = `${baseUrl}/testimonials`;
  static minddrops = `${baseUrl}/minddrops`;
  static moods = `${baseUrl}/moods`;
  static knowHows = `${baseUrl}/know-hows`;
  static soundscapes = `${baseUrl}/soundscapes`;
  static exercises = `${baseUrl}/exercises`;
  static collections = `${baseUrl}/collections`;
  static coupons = {
    redeem: `${baseUrl}/coupons/redeem`,
  };
  static stripe = {
    products: `${baseUrl}/stripe/products`,
    checkoutSession: `${baseUrl}/stripe/checkout-session`,
  };
}

export default Endpoints;
