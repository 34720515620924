import Button from "@mui/joy/Button";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import logo94 from "src/assets/images/logo94x94.png";

import { Component, ReactNode } from "react";
import { Image } from "../image";
import {
  ButtonOptionStyles,
  ModalDialogProps,
  ModalDialogState,
} from "./index.interfaces";

export class ModalDialogComponent extends Component<
  ModalDialogProps,
  ModalDialogState
> {
  state: Readonly<ModalDialogState> = {
    isOpen: false,
  };

  componentDidMount(): void {
    if (this.props.openController) {
      this.setState({
        isOpen: this.props.openController,
      });
    }
  }

  onClose = () => {
    this.setState({
      isOpen: false,
    });
    this.props.onOpenControllerChanged &&
      this.props.onOpenControllerChanged(false);
  };

  onOpen = () => {
    this.setState({
      isOpen: true,
    });
    this.props.onOpenControllerChanged &&
      this.props.onOpenControllerChanged(true);
  };

  onAcceptButtonClicked = () => {
    const { acceptButton } = this.props;
    acceptButton?.onClick();
    this.onClose();
  };

  onCancelButtonClicked = () => {
    const { cancelButton } = this.props;
    cancelButton?.onClick();
    this.onClose();
  };

  render(): ReactNode | null {
    const {
      title,
      dialogText,
      acceptButton,
      cancelButton,
      openController,
      onOpenControllerChanged,
      showLogo,
    } = this.props;

    const { isOpen } = this.state;
    if (!acceptButton && !cancelButton) return null;

    return (
      <>
        {openController === undefined && (
          <button
            onClick={this.onOpen}
            className="absolute top-0 right-0 w-full h-full"
          />
        )}
        <Modal
          open={openController || isOpen}
          onClose={onOpenControllerChanged || this.onClose}
        >
          <ModalDialog
            variant="outlined"
            role="none"
            className="!min-w-[320px] !w-[calc(100%-3rem)] !max-w-[480px] !gap-10 !rounded-xl !p-6"
          >
            {/* header */}
            <div className="flex flex-col gap-5 items-center text-center">
              {/* logo */}
              {showLogo && (
                <Image src={logo94} alt="app-logo" className="w-12 h-12" />
              )}
              {/* title */}
              <DialogTitle className="!text-normal !font-new-atten-medium !leading-7">
                {title.toUpperCase()}
              </DialogTitle>
              {/* text */}
              {dialogText.isNotEmpty() && (
                <DialogContent>{dialogText}</DialogContent>
              )}
            </div>
            {/* aaaa */}
            <DialogActions>
              {/* accept button */}
              {acceptButton && (
                <Button
                  onClick={this.onAcceptButtonClicked}
                  className={`!rounded-full !w-full !border-solid !border-1 ${
                    acceptButton.style === ButtonOptionStyles.primary
                      ? "!bg-primary !text-background !border-transparent hover:brightness-95 "
                      : "!bg-transparent !text-primary !border-primary hover:!bg-black hover:!bg-opacity-5"
                  }`}
                >
                  {acceptButton.label}
                </Button>
              )}
              {/* cancel button */}
              {cancelButton && (
                <Button
                  onClick={this.onCancelButtonClicked}
                  className={`!rounded-full !w-full !border-solid !border-1 ${
                    cancelButton.style === ButtonOptionStyles.primary
                      ? "!bg-primary !text-background !border-transparent hover:brightness-95 "
                      : "!bg-transparent !text-primary !border-primary hover:!bg-black hover:!bg-opacity-5"
                  }`}
                >
                  {cancelButton.label}
                </Button>
              )}
            </DialogActions>
          </ModalDialog>
        </Modal>
      </>
    );
  }
}
