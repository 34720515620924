import StripesController from "./index.controller";

import { Stripe } from "./index.models";

class StripesRepository {
  async get(): Promise<Stripe[]> {
    const response = await StripesController.get();
    return response;
  }

  async sendCheckoutSession(priceIds: string[]) {
    const response = await StripesController.sendCheckoutSession(priceIds);
    return response;
  }
}

export default StripesRepository;
