import AxiosClient from "src/networking/axios-client/index.client";
import Endpoints from "src/constants/endpoints";

export default class UserController {
  static async signInWithGoogleAccessToken({
    accessToken,
  }: {
    accessToken: string;
  }): Promise<{
    authToken: string;
    onlineDataStorageActivated: boolean;
  }> {
    const res = await AxiosClient.post({
      url: Endpoints.user.auth.signIn.google,
      parser: (res) => {
        return {
          authToken: res.data.authToken,
          onlineDataStorageActivated: res.data.onlineDataStorageActivated,
        };
      },
      body: {
        accessToken,
      },
    });

    return res;
  }

  static async signInWithAppleIdentityToken({
    identityToken,
    nickname,
  }: {
    identityToken: string;
    nickname?: string;
  }): Promise<{
    authToken: string;
    onlineDataStorageActivated: boolean;
  }> {
    const body: any = {
      identityToken,
    };

    if (nickname) {
      body.nickname = nickname;
    }

    const res = await AxiosClient.post({
      url: Endpoints.user.auth.signIn.apple,
      parser: (res) => {
        return {
          authToken: res.data.authToken,
          onlineDataStorageActivated: res.data.onlineDataStorageActivated,
        };
      },
      body,
    });

    return res;
  }

  static async requestVerification(emailAddress: string): Promise<string> {
    const body: any = {
      emailAddress,
    };

    const res = await AxiosClient.post({
      url: Endpoints.user.auth.email.requestVerification,
      parser: (res) => {
        return res.data.message;
      },
      body,
    });

    return res;
  }

  static async forgotPassword(email: string): Promise<string> {
    const body: any = {
      email,
    };

    const res = await AxiosClient.post({
      url: Endpoints.user.auth.forgotPassword.emailAutoReset,
      parser: (res) => {
        return res.data.message;
      },
      body,
    });

    return res;
  }
}
