import { Component, ReactNode } from "react";
import { ArrowUpIcon } from "src/assets/icons";
import { SimpleDropdownSelectInputModal } from "./index.modal";
import { Skeleton } from "src/shared-components/skeleton";
import {
  SimpleDropdownSelectInputProps,
  SimpleDropdownSelectInputState,
} from "./index.interfaces";

class SimpleDropdownSelectInput extends Component<
  SimpleDropdownSelectInputProps,
  SimpleDropdownSelectInputState
> {
  state: Readonly<SimpleDropdownSelectInputState> = {
    isModalOpen: false,
    errorMessage: null,
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  onModalButtonClick = () => {
    const { isModalOpen } = this.state;
    isModalOpen ? this.closeModal() : this.openModal();
  };

  isThereMoreThanOneVersion = (): boolean => {
    const { values } = this.props;
    if (!values) return false;
    return values.length > 1;
  };

  render(): ReactNode {
    const { id, name, label, values, selectedValue, onChange } = this.props;
    const { isModalOpen } = this.state;

    return values ? (
      <div className="flex flex-col gap-4 items-center mx-auto tablet:mx-0 tablet:items-start min-w-fit w-full max-w-[288px] relative text-xsm">
        <label htmlFor={id}>{label}</label>
        <button
          className="min-w-fit w-full max-w-[288px] h-10 px-3 py-2 rounded-md appearance-none text-left bg-dominant-200 disabled:cursor-default"
          name={name}
          id={id}
          onClick={this.onModalButtonClick}
          disabled={!this.isThereMoreThanOneVersion()}
        >
          {selectedValue}
          {this.isThereMoreThanOneVersion() && (
            <ArrowUpIcon
              className={`absolute z-10 top-[50px] right-4 fill-on-background w-5 h-5 ${
                !isModalOpen && "rotate-180"
              }`}
            />
          )}
        </button>

        {isModalOpen && this.isThereMoreThanOneVersion() && (
          <SimpleDropdownSelectInputModal
            onChange={onChange}
            selectedValue={selectedValue}
            values={values}
            closeModal={this.closeModal}
          />
        )}
      </div>
    ) : (
      <Skeleton />
    );
  }
}

export default SimpleDropdownSelectInput;
