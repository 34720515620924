import ShopPrivacyPolicyScreen from "./screens/privacy-policy";
import ShopRevocationRightScreen from "./screens/revocation-right";

import { auth } from "src/firebase";
import { RoutesEnum } from "src/enums/Routes";
import { ShopHomeScreen } from "./screens/home";
import { ErrorHandler } from "src/abstracts/handleError";
import { useCallback, useEffect, useState } from "react";
import { HeaderComponent } from "src/shared-components/header";
import { Stripe, StripesRepository } from "src/components/stripe";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

export default function ShopScreen() {
  const [products, setProducts] = useState<Stripe[] | null>(null);
  const location = useLocation();

  const fetchProducts = useCallback(async () => {
    try {
      const products = await new StripesRepository().get();
      setProducts(products);
    } catch (e) {
      ErrorHandler.displayError(e);
    }
  }, []);

  const redirectToSignin = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        const currentUrl = location.pathname.split("/")[1];
        window.location.replace(
          `${RoutesEnum.signin}?callbackUrl=${currentUrl}`
        );
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    fetchProducts();
    redirectToSignin();
  }, [fetchProducts, redirectToSignin]);

  return (
    <main
      className={`min-w-[350px] min-h-[100svh] flex flex-col pb-0 desktop:!pb-0 desktop:bg-white justify-between mx-auto`}
    >
      <HeaderComponent />
      <Routes>
        <Route path="/" element={<ShopHomeScreen products={products} />} />
        <Route path="/privacy-policy" element={<ShopPrivacyPolicyScreen />} />
        <Route
          path="/revocation-right"
          element={<ShopRevocationRightScreen />}
        />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </main>
  );
}
