import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class MessageQuestionIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="vuesax/linear/message-question">
          <g id="message-question">
            <path
              id="Vector"
              d="M17 18.9297H13L8.54999 21.8897C7.88999 22.3297 7 21.8598 7 21.0598V18.9297C4 18.9297 2 16.9297 2 13.9297V7.92969C2 4.92969 4 2.92969 7 2.92969H17C20 2.92969 22 4.92969 22 7.92969V13.9297C22 16.9297 20 18.9297 17 18.9297Z"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M11.9998 11.8604V11.6504C11.9998 10.9704 12.4198 10.6104 12.8398 10.3204C13.2498 10.0404 13.6598 9.68039 13.6598 9.02039C13.6598 8.10039 12.9198 7.36035 11.9998 7.36035C11.0798 7.36035 10.3398 8.10039 10.3398 9.02039"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M11.9955 14.25H12.0045"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}
