export class DateFormatter {
  public static format(date: Date): string {
    // get day, month, and year
    const day = date.getDate().toString().padStart(2, "0"); // ensure 2-digit format with leading zero
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // months are zero-based, so add 1
    const year = date.getFullYear();

    // format date as "DAY.MONTH.YEAR"
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  }
}
