import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class LogoutIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="Logout">
          <path
            id="Stroke 1"
            d="M20.791 10.8707H8.75"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M17.8643 7.9547L20.7923 10.8707L17.8643 13.7867"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 4"
            d="M15.3598 6.3799C15.0298 2.7999 13.6898 1.4999 8.3598 1.4999C1.2588 1.4999 1.2588 3.8099 1.2588 10.7499C1.2588 17.6899 1.2588 19.9999 8.3598 19.9999C13.6898 19.9999 15.0298 18.6999 15.3598 15.1199"
            strokeWidth="1.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
}
