import { AppLogo } from "./components/logo";
import { Component, ReactNode } from "react";
import { HeaderCTAs } from "./components/ctas";
import { withTranslation } from "react-i18next";
import { HOME_HEADER_LINK } from "src/constants/links";
import { WithTFunction } from "src/types/WithTFunction";
import { HeaderProps, HeaderState } from "./index.interfaces";

class HeaderComponent extends Component<
  WithTFunction<HeaderProps>,
  HeaderState
> {
  render(): ReactNode {
    const { showLogoutButton } = this.props;

    return (
      <nav className="flex w-full justify-between items-center py-6 px-10 sticky top-0 z-50 bg-white drop-shadow-md">
        <AppLogo iconOnClickUrl={HOME_HEADER_LINK} />

        <HeaderCTAs showLogoutButton={showLogoutButton} />
      </nav>
    );
  }
}

export default withTranslation()(HeaderComponent);
