import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { Form } from "src/shared-components/form";
import { SimpleTextInput } from "src/shared-components/form/fields/text-fields/simple-text-input";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { Skeleton } from "src/shared-components/skeleton";
import {
  RedeemCodeScreenSubmitCodeFormProps,
  RedeemCodeScreenSubmitCodeFormState,
} from "./index.interfaces";

class RedeemCodeScreenSubmitCodeForm extends Component<
  WithTFunction<RedeemCodeScreenSubmitCodeFormProps>,
  RedeemCodeScreenSubmitCodeFormState
> {
  render(): ReactNode {
    const { code, onCodeChanged, onDisableSubmitButton, onSubmit, user, t } =
      this.props;

    return (
      <Form className="flex flex-col gap-6" onSubmit={onSubmit}>
        {/* helper text */}
        <div className="flex flex-col gap-3">
          {/* user's email */}
          {user !== null ? (
            <h1 className="opacity-40">{user.email}</h1>
          ) : (
            <Skeleton className="w-20 h-6" numberOfSkeletons={1} />
          )}
          {/* title */}
          <h1 className="text-lg font-new-atten-medium">
            {t(Translation.screens.redeemCode.form.title).toUpperCase()}
          </h1>
          {/* helper text */}
          <h1 className="opacity-40 font-new-atten-medium">
            {t(Translation.screens.redeemCode.form.helperText)}
          </h1>
          {/* sub helper text */}
          <h1 className="opacity-40 font-new-atten-medium">
            {t(Translation.screens.redeemCode.form.subHelperText)}
          </h1>
        </div>
        <div className="flex flex-col w-full gap-6">
          {/* code input */}
          <SimpleTextInput
            id="code"
            name="code"
            placeholder={t(Translation.screens.redeemCode.form.codePlaceholder)}
            value={code}
            onChange={onCodeChanged}
            maxLength={8}
          />
          {/* submit button */}
          <SimpleElevatedButton
            id="redeem-code-button"
            disabled={onDisableSubmitButton()}
            onClick={onSubmit}
            text={t(Translation.screens.redeemCode.form.redeemButton)}
          />
        </div>
      </Form>
    );
  }
}

export default withTranslation()(RedeemCodeScreenSubmitCodeForm);
