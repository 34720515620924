import { Component, ReactNode } from "react";
import { FAQScreenFAQItem } from "../faq-item";
import {
  FAQScreenFAQContainerProps,
  FAQScreenFAQContainerState,
} from "./index.interfaces";

export default class FAQScreenFAQContainer extends Component<
  FAQScreenFAQContainerProps,
  FAQScreenFAQContainerState
> {
  render(): ReactNode {
    const { faqs } = this.props;

    return faqs ? (
      <div>
        {faqs.map((faq, i) => (
          <FAQScreenFAQItem
            key={faq.title + i}
            title={faq.title}
            body={faq.body}
          />
        ))}
      </div>
    ) : (
      <div className="w-full">
        {Array.from({ length: 20 }, (_, i) => (
          <FAQScreenFAQItem key={"faq" + i} title={null} body={null} />
        ))}
      </div>
    );
  }
}
