export enum ButtonOptionStyles {
  primary = "primary",
  secondary = "secondary",
}

interface ButtonOption {
  label: string;
  onClick: () => void;
  style: ButtonOptionStyles;
}

export interface ModalDialogProps {
  title: string;
  dialogText: string;
  acceptButton?: ButtonOption;
  cancelButton?: ButtonOption;
  openController?: boolean;
  onOpenControllerChanged?: (isOpen: boolean) => void;
  showLogo?: boolean;
}

export interface ModalDialogState {
  isOpen: boolean;
}
