import { Component, ReactNode } from "react";
import { IconComponentProps } from "../index.interfaces";

export class TickCircleIcon extends Component<IconComponentProps> {
  render(): ReactNode {
    const { className } = this.props;

    return (
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <g id="vuesax/linear/tick-circle">
          <g id="tick-circle">
            <path
              id="Vector"
              d="M49.9997 91.6673C72.9163 91.6673 91.6663 72.9173 91.6663 50.0007C91.6663 27.084 72.9163 8.33398 49.9997 8.33398C27.083 8.33398 8.33301 27.084 8.33301 50.0007C8.33301 72.9173 27.083 91.6673 49.9997 91.6673Z"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M32.292 49.9997L44.0837 61.7913L67.7087 38.208"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }
}
