import Translation from "src/translations/locales/translation";
import minddropsLogo from "src/assets/images/minddrops-logo.png";

import { ReactNode } from "react";
import { SimpleTextInput } from "src/shared-components/form/fields/text-fields/simple-text-input";
import { SimplePasswordInput } from "src/shared-components/form/fields/text-fields/simple-password-input";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { AppleProviderButton } from "src/shared-components/form/fields/buttons/provider-buttons/apple";
import { GoogleProviderButton } from "src/shared-components/form/fields/buttons/provider-buttons/google";
import { SimpleElevatedButton } from "src/shared-components/form/fields/buttons/simple-elevated-button";
import { Form } from "src/shared-components/form";
import { auth } from "src/firebase";
import { DynamicForm } from "src/composable/base-dynamic-form";
import { RoutesEnum } from "src/enums/Routes";
import { EmailValidator } from "src/components/email";
import { FirebaseAuthErrorHandler } from "src/utils/FirebaseAuthErrorHandler";
import { Image } from "src/shared-components/image";
import { signInWithEmailAndPassword } from "firebase/auth";
import { SafeLink } from "src/shared-components/safe-link";
import { ArrowUpRightIcon } from "src/assets/icons";
import {
  SignInScreenFormProps,
  SignInScreenFormState,
} from "./index.interfaces";

class SignInScreenForm extends DynamicForm<
  WithTFunction<SignInScreenFormProps>,
  SignInScreenFormState
> {
  _emailValidator: EmailValidator;

  state: Readonly<SignInScreenFormState> = {
    email: "",
    password: "",
    isLoading: false,
  };

  constructor(props: WithTFunction<SignInScreenFormProps>) {
    super(props);
    this._emailValidator = new EmailValidator(props.t);
  }

  onEmailChanged = (email: string) => {
    this.setState({ email });
  };

  onPasswordChanged = (password: string) => {
    this.setState({ password });
  };

  onDisableSubmitButton = (): boolean => {
    const { email, password } = this.state;
    return !email.trim() || !password.trim();
  };

  onSubmit = async () => {
    const { callbackUrl } = this.props;

    const { email, password, isLoading } = this.state;

    if (this._emailValidator.validateEmail(this.state.email)) return;

    if (!email.trim() || !password.trim() || isLoading) return;

    this.setState({ isLoading: true });

    try {
      await signInWithEmailAndPassword(auth, email, password);

      window.location.href = callbackUrl;
    } catch (e: any) {
      FirebaseAuthErrorHandler.handle(e, this.props.t);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render(): ReactNode {
    const { t, callbackUrl } = this.props;
    const { email, password } = this.state;

    return (
      <section className="flex flex-col gap-10 w-full max-w-[300px] py-16 tablet:py-28 desktop:py-0 desktop:min-w-[50vw] desktop:px-[12.5%]">
        {/* app name and logo */}
        <Image
          className="w-32"
          src={minddropsLogo}
          alt={t(Translation.common.minddrops)}
        />

        {/* title and sub title */}
        <div className="flex flex-col gap-4">
          <h1 className="text-lg font-new-atten-medium">
            {t(Translation.screens.signin.form.title).toUpperCase()}
          </h1>
          <h2 className="opacity-50 text-normal">
            {t(Translation.screens.signin.form.subTitle)}
          </h2>
        </div>

        <div className="flex flex-col gap-4">
          {/* google and apple buttons */}
          <div className="flex flex-col gap-4">
            <GoogleProviderButton
              id="signin-with-google"
              callbackUrl={callbackUrl}
            />
            <AppleProviderButton
              id="signin-with-apple"
              callbackUrl={callbackUrl}
            />
          </div>

          {/* line between buttons and inputs and "OR" text */}
          <div className="flex items-center justify-center">
            <p className="text-xsm opacity-50">
              {t(Translation.screens.signin.form.or)}
            </p>
          </div>

          {/* signin with email and password form */}
          <Form onSubmit={this.onSubmit} className="flex flex-col gap-6">
            {/* inputs */}
            <div className="flex flex-col gap-3">
              {/* email input */}
              <SimpleTextInput
                id="email"
                name="email"
                placeholder={t(
                  Translation.screens.signin.form.emailPlaceholder
                )}
                value={email}
                onChange={this.onEmailChanged}
                maxLength={100}
                validator={this._emailValidator.validateEmail}
              />
              {/* password input */}
              <SimplePasswordInput
                id="password"
                name="password"
                placeholder={t(
                  Translation.screens.signin.form.passwordPlaceholder
                )}
                value={password}
                onChange={this.onPasswordChanged}
                maxLength={100}
                validator={() => null}
              />
              {/* forgot password */}
              <SafeLink
                className="flex items-center gap-1 self-end"
                linkClassName="flex w-full h-full items-center gap-1"
                to={RoutesEnum.forgotPassword}
              >
                <h1 className="text-on-background opacity-70 text-sm">
                  {t(
                    Translation.screens.signin.form.forgotPassword
                  ).toUpperCase()}
                </h1>
                <ArrowUpRightIcon className="stroke-on-background opacity-70" />
              </SafeLink>
            </div>

            {/* button */}
            <SimpleElevatedButton
              id="signin-button"
              disabled={this.onDisableSubmitButton()}
              onClick={this.onSubmit}
              text={t(Translation.screens.signin.form.signinButton)}
            />
          </Form>
        </div>
      </section>
    );
  }
}

export default withTranslation()(SignInScreenForm);
