import { Component } from "react";
import { motion } from "framer-motion";
import { Divider } from "@mui/joy";
import { ArrowUpIcon } from "src/assets/icons";
import { MarkDownContentDisplay } from "src/shared-components/mark-down-content-display";
import { Skeleton } from "src/shared-components/skeleton";
import {
  FAQScreenFAQItemProps,
  FAQScreenFAQItemState,
} from "./index.interfaces";

export default class FAQScreenFAQItem extends Component<
  FAQScreenFAQItemProps,
  FAQScreenFAQItemState
> {
  state: Readonly<FAQScreenFAQItemState> = {
    isOpen: false,
  };
  toggleAccordion = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { title, body } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="flex flex-col py-3 gap-3">
        {title ? (
          <button
            onClick={this.toggleAccordion}
            className="w-full text-left rounded-md flex justify-between items-center gap-2 pr-4"
          >
            <h1 className="font-new-atten-medium flex-1">{title}</h1>
            <ArrowUpIcon
              className={`fill-on-background ${!isOpen && "rotate-180"}`}
            />
          </button>
        ) : (
          <Skeleton className="w-full rounded-md h-6" numberOfSkeletons={1} />
        )}
        <motion.div
          className="overflow-hidden"
          initial={{ height: 0 }}
          animate={{ height: isOpen ? "auto" : 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <MarkDownContentDisplay className="opacity-50" content={body} />
        </motion.div>
        <Divider />
      </div>
    );
  }
}
