import theme from "./theme";
import Home from "./screens/home";
import FAQScreen from "./screens/faq";
import ShopScreen from "./screens/shop";
import SignInScreen from "./screens/sign-in";
import ProfileScreen from "./screens/profile";
import ImprintScreen from "./screens/imprint";
import ExercisesScreen from "./screens/exercises";
import MinddropsScreen from "./screens/minddrops";
import RedeemCodeScreen from "./screens/redeem-code";
import SoundscapesScreen from "./screens/soundscapes";
import CollectionsScreen from "./screens/collections";
import PrivacyPolicyScreen from "./screens/privacy-policy";
import ForgotPasswordScreen from "./screens/forgot-password";
import TermsOfServiceScreen from "./screens/terms-of-service";
import PaymentResponseScreen from "./screens/payment-response";
import HowToDeleteMyAccountScreen from "./screens/how-to-delete-my-account";
import EmailVerificationResponseScreen from "./screens/email-verification-response";
import AppleHealthWriteErrorGuideScreen from "./screens/apple-health-write-error-guide";

import { CssVarsProvider } from "@mui/joy";
import { RoutesEnum } from "./enums/Routes";
import { Component, ReactNode } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { withTranslation } from "react-i18next";
import { Elements } from "@stripe/react-stripe-js";
import { WithTFunction } from "./types/WithTFunction";
import { AuthResponse } from "./screens/auth/response";
import { Snackbar, SnackbarService } from "./shared-components/snackbar";
import { ScrollToTapButton } from "./shared-components/scroll-to-top-button";
import { ConsentBannerProps } from "./shared-components/consent-banner/index.interfaces";

import {
  SnackbarProps,
  SnackbarTypes,
} from "./shared-components/snackbar/index.interfaces";

import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface AppState {
  snackbar: SnackbarProps;
}

class App extends Component<WithTFunction<{}>> {
  state: Readonly<AppState> = {
    snackbar: {
      message: null,
      type: SnackbarTypes.success,
    },
  };

  showSnackbarCallback = (newSnackbar: SnackbarProps) => {
    this.setState({ snackbar: newSnackbar });
  };

  showConsentBannerCallback = (newConsentBanner: ConsentBannerProps) => {
    this.setState({ consentBanner: newConsentBanner });
  };

  componentDidMount() {
    SnackbarService.getInstance().setShowSnackbarCallback(
      this.showSnackbarCallback
    );
  }

  componentDidUpdate() {
    SnackbarService.getInstance().setShowSnackbarCallback(
      this.showSnackbarCallback
    );
  }

  render(): ReactNode {
    const { snackbar } = this.state;

    const options = {};

    return (
      <CssVarsProvider theme={theme}>
        <Elements stripe={stripePromise} options={options}>
          <Router>
            <Snackbar {...snackbar} />
            <Routes>
              <Route path={RoutesEnum.home} element={<Home />} />
              <Route
                path={RoutesEnum.privacyPolicy}
                element={<PrivacyPolicyScreen />}
              />
              <Route
                path={RoutesEnum.termsOfService}
                element={<TermsOfServiceScreen />}
              />
              <Route path={RoutesEnum.imprint} element={<ImprintScreen />} />
              <Route
                path={RoutesEnum.forgotPassword}
                element={<ForgotPasswordScreen />}
              />
              <Route path={RoutesEnum.signin} element={<SignInScreen />} />
              <Route path={RoutesEnum.profile} element={<ProfileScreen />} />
              <Route
                path={RoutesEnum.redeemCode}
                element={<RedeemCodeScreen />}
              />
              <Route
                path={RoutesEnum.howToDeleteMyAccount}
                element={<HowToDeleteMyAccountScreen />}
              />
              <Route
                path={RoutesEnum.appleHealthWriteErrorGuide}
                element={<AppleHealthWriteErrorGuideScreen />}
              />
              <Route
                path={RoutesEnum.emailVerificationResponse}
                element={<EmailVerificationResponseScreen />}
              />
              <Route
                path={RoutesEnum.soundscapes}
                element={<SoundscapesScreen />}
              />
              <Route
                path={RoutesEnum.collections}
                element={<CollectionsScreen />}
              />
              <Route
                path={RoutesEnum.exercises}
                element={<ExercisesScreen />}
              />
              <Route
                path={RoutesEnum.minddrops}
                element={<MinddropsScreen />}
              />
              <Route
                path={RoutesEnum.authResponse}
                element={<AuthResponse />}
              />
              <Route path={RoutesEnum.faq} element={<FAQScreen />} />
              <Route path={`${RoutesEnum.shop}*`} element={<ShopScreen />} />
              <Route
                path={RoutesEnum.paymentResponse}
                element={<PaymentResponseScreen />}
              />
              <Route path="*" element={<Navigate to={"/"} />} />
            </Routes>
            <ScrollToTapButton />
          </Router>
        </Elements>
      </CssVarsProvider>
    );
  }
}

export default withTranslation()(App);
