class Minddrop {
  private _id: string;
  private _active: boolean;
  private _title: string;
  private _subtitle: string;
  private _thumbnail: string;
  private _exerciseId: string;

  constructor({
    id,
    active,
    title,
    subtitle,
    thumbnail,
    exerciseId,
  }: {
    id: string;
    active: boolean;
    title: string;
    subtitle: string;
    thumbnail: string;
    exerciseId: string;
  }) {
    this._id = id;
    this._active = active;
    this._title = title;
    this._subtitle = subtitle;
    this._thumbnail = thumbnail;
    this._exerciseId = exerciseId;
  }

  // getters
  get id(): string {
    return this._id;
  }

  get active(): boolean {
    return this._active;
  }

  get title(): string {
    return this._title;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  get thumbnail(): string {
    return this._thumbnail;
  }

  get exerciseId(): string {
    return this._exerciseId;
  }

  public static fromJson(data: any): Minddrop {
    return new Minddrop({
      id: data.id,
      active: data.active,
      title: data.title,
      subtitle: data.subtitle,
      thumbnail: data.thumbnail,
      exerciseId: data.exerciseId,
    });
  }

  public static maybeFromJson(data: any): Minddrop | null {
    try {
      return Minddrop.fromJson(data);
    } catch (e) {
      return null;
    }
  }
}

export { Minddrop };
