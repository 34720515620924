import Translation from "src/translations/locales/translation";

import { Component, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { WithTFunction } from "src/types/WithTFunction";
import { ArrowUpRightIcon } from "src/assets/icons";
import { SafeLink } from "src/shared-components/safe-link";
import { Divider } from "@mui/joy";
import {
  RegistrationInstructionViewProps,
  RegistrationInstructionViewState,
} from "./index.interfaces";
import {
  APP_STORE_DOWNLOAD_LINK,
  GOOGLE_PLAY_DOWNLOAD_LINK,
} from "src/constants/links";

class RegistrationInstructionView extends Component<
  WithTFunction<RegistrationInstructionViewProps>,
  RegistrationInstructionViewState
> {
  private buildDownloadText(options: { text: string; link: string }[]) {
    return options.map((option) => (
      <SafeLink
        key={option.text + option.link}
        className="w-fit flex gap-1 items-center text-link-color"
        linkClassName="w-fit flex gap-1 items-center text-link-color"
        to={option.link}
      >
        <span>{option.text}</span>
        <ArrowUpRightIcon className="stroke-link-color" />
      </SafeLink>
    ));
  }

  render(): ReactNode {
    const { t } = this.props;

    return (
      <section className="w-full h-full flex-1 overflow-auto scrollbar-none justify-center bg-white text-on-background px-10 py-6 tablet:py-16 tablet:px-20 desktop:px-24 desktop:py-40 flex flex-col gap-6 tablet:gap-10 desktop:gap-16">
        <div className="w-full flex flex-col gap-4">
          {/* title */}
          <h1 className="text-lg font-new-atten-medium">
            {t(
              Translation.screens.signin.registrationInstruction.title
            ).toUpperCase()}
          </h1>

          {/* line behind title */}
          <Divider />
        </div>

        {/* list */}
        <ul className="flex flex-col gap-6 tablet:gap-10 list-decimal list-outside pl-3">
          <li>
            {t(Translation.screens.signin.registrationInstruction.downloadText)}
            <p className="flex flex-wrap pt-2 gap-2">
              {this.buildDownloadText([
                {
                  text: t(
                    Translation.screens.signin.registrationInstruction
                      .downloadForAndroid
                  ),
                  link: GOOGLE_PLAY_DOWNLOAD_LINK,
                },
                {
                  text: t(
                    Translation.screens.signin.registrationInstruction
                      .downloadForIos
                  ),
                  link: APP_STORE_DOWNLOAD_LINK,
                },
              ])}
            </p>
          </li>
          <li>
            {t(
              Translation.screens.signin.registrationInstruction.createUserText
            )}
          </li>
          <li>
            {t(
              Translation.screens.signin.registrationInstruction.signinUserText
            )}
          </li>
          <li>
            {t(
              Translation.screens.signin.registrationInstruction.redeemCodeText
            )}
          </li>
          <li>
            {t(
              Translation.screens.signin.registrationInstruction.afterRedeemText
            )}
          </li>
        </ul>
      </section>
    );
  }
}

export default withTranslation()(RegistrationInstructionView);
