import Translation from "src/translations/locales/translation";

import { useTranslation } from "react-i18next";
import { GetUrlParams } from "src/utils/GetUrlParams";
import { Alignment } from "src/components/alignment";
import { ErrorHandler } from "src/abstracts/handleError";
import { DyanmicOverview } from "src/composable/base-dynamic-overview";
import { Minddrop, MinddropsRepository } from "src/components/minddrop";
import { Exercise, ExercisesRepository } from "src/components/exercises";
import { DynamicOverviewState } from "src/composable/base-dynamic-overview/index.interfaces";

class InnerMinddropsOverviewScreen extends DyanmicOverview<Minddrop> {
  _exercise: Exercise | null = null;

  state: Readonly<DynamicOverviewState<Minddrop>> & {
    exercise: Exercise | null;
  } = {
    ...this.state,
    exercise: null,
  };

  getTitle(): string | null {
    return this.state.item?.title ?? "";
  }

  getDescription(): string | null {
    return this._exercise?.description ?? "";
  }

  getThumbnail(): string | null {
    return this._exercise?.thumbnail ?? "";
  }

  getMoreInfoText(): string | null {
    return this.props.t(Translation.screens.overviews.moreInfoText);
  }

  getThumbnailAlignment(): Alignment | null {
    return Alignment.center;
  }

  initializeData = async (): Promise<void> => {
    const { id } = this.props;

    if (id) {
      this.setState({ isLoading: true });

      try {
        const items = await this.props.loadItem();
        const item = items.find((s) => s.id === id);
        if (item) {
          const relatedExercise = (
            await new ExercisesRepository().get({})
          ).find((e) => e.id === item.exerciseId);
          this.setState({ item: item });
          this._exercise = relatedExercise ?? null;
        } else {
          this.setState({ dataNotFound: true });
        }
      } catch (e) {
        ErrorHandler.displayError(e);
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };
}

export default function MinddropsOverviewScreen() {
  const { t } = useTranslation();

  const id = GetUrlParams();

  return (
    <InnerMinddropsOverviewScreen
      loadItem={async () => {
        return await new MinddropsRepository().get({});
      }}
      id={id ?? null}
      t={t}
    />
  );
}
