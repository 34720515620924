import Translation from "src/translations/locales/translation";
import logo94x94 from "src/assets/images/logo94x94.png";

import { Component, ReactNode } from "react";
import { WithTFunction } from "src/types/WithTFunction";
import { withTranslation } from "react-i18next";
import { DownloadOptionsBuilder } from "src/utils/DownloadOptionsBuilder";
import { SimpleDropdownSelectInput } from "src/shared-components/form/fields/selectors/simple-dropdown-select-input";
import { SafeLink } from "src/shared-components/safe-link";
import { Image } from "src/shared-components/image";
import {
  TermsOfServiceScreenFooterProps,
  TermsOfServiceScreenFooterState,
} from "./index.interfaces";

class TermsOfServiceScreenFooter extends Component<
  WithTFunction<TermsOfServiceScreenFooterProps>,
  TermsOfServiceScreenFooterState
> {
  render(): ReactNode {
    const { t, versionSelectorConfig } = this.props;

    // if the selected version is not present, add it to the values array
    if (versionSelectorConfig.values && versionSelectorConfig.selectedValue) {
      if (
        !versionSelectorConfig.values.includes(
          versionSelectorConfig.selectedValue
        )
      ) {
        versionSelectorConfig.values.push(versionSelectorConfig.selectedValue);
      }
    }

    return (
      <footer className="flex flex-col gap-14">
        {/* select versions */}
        <SimpleDropdownSelectInput
          id="terms-of-service-footer-version-selector"
          name="terms-of-service-footer-version-selector"
          label={t(
            Translation.screens.termsOfService.footer.selectVersionLabel
          )}
          placeholder=""
          selectedValue={versionSelectorConfig.selectedValue}
          onChange={versionSelectorConfig.onSelectedInputChange}
          values={versionSelectorConfig.values}
        />

        {/* download options */}
        <div className="flex min-w-full flex-wrap gap-5 items-center justify-center tablet:justify-between bg-dominant-200 p-6 rounded-xl">
          <div className="flex items-center gap-2">
            <Image src={logo94x94} className="w-8 h-8" alt="minddrops-logo" />
            <h1>
              {t(
                Translation.screens.termsOfService.footer.downloadOptionsLabel
              )}
            </h1>
          </div>
          <div className="flex flex-wrap mobile:w-full items-center justify-center tablet:justify-between gap-4">
            {DownloadOptionsBuilder.build(t).map((option, i) => (
              <SafeLink
                to={option.link}
                key={`app-insight-download-options-${i}`}
                className="flex gap-2 hover:brightness-90 items-center w-40 justify-center bg-on-background rounded-lg"
              >
                <Image
                  className="w-40 h-11 object-cover rounded-lg"
                  src={option.badgeSrc}
                  alt={option.link}
                />
              </SafeLink>
            ))}
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(TermsOfServiceScreenFooter);
